.modal {

    .modal-dialog {
        margin: 20px;
    }

    .modal-body {
        padding: 50px 15px 40px;

        p {
            margin-bottom: 1.35rem;
        }
        .btn {
            display: block;

            width: 100%;

            + .btn {
                margin-top: 10px;
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .modal-title {
        margin-top: 0;
        margin-bottom: 0.75rem;

        font-size: 1.5rem;
        line-height: 1.2;
    }

    .modal-list {
        margin-right: -15px;
        margin-left: -15px;
        a {
            color: $primary;
        }
        .dropdown-item {
            padding: 18px 1rem;

            white-space: normal;

            border-top: solid 1px rgba(0,0,0,.18);
            &.active, &:active {
                color: #fff;
            }
        }
        .dropdown-item--with-icon {
            position: relative;

            padding-left: 2.5rem;

            color: $blue;

            font-weight: 700;
            i {
                position: absolute;
                top: 21px;
                left: 1rem;
            }
        }
    }

    @media only screen and (min-width: $width-sm) {
        .modal-dialog {
            margin-right: auto;
            margin-left: auto;
        }
        .modal-body {

            .btn {
                display: inline-block;

                width: auto;

                + .btn {
                    margin-top: 0px;
                }
            }
        }
    }

    @media only screen and (min-width: $width-md) {
        .modal-dialog {
            max-width: 600px;
        }
        .modal-body {
            padding: 50px 40px 40px;
        }
        .modal-title {
            font-size: 1.75rem;
        }
    }
}

.modal--menu {
    .modal-body {
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .close {
        top: 13px;
        right: 13px;
        &:before, &:after {
            left: calc(50% - 16px);

            width: 32px;
            height: 4px;

            border-radius: 2px;
        }
    }

    .modal-title {
        margin-right: 50px;
        margin-bottom: 1.0rem;

        font-size: 30px;
    }
    @media only screen and (min-width: $width-md) {
        .modal-body {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

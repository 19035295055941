.l-header {
    background-color: $color-background-color;
}

.l-nav__list {
    a {
        display: block;
        color: $color-background-color;
        text-decoration: none;
    }


    .current,
    .active {
        a {
            border-left: .188rem solid $color-background-color;
        }
    }

    @media (min-width: $off-canvas-breakpoint) {
        a {
            color: white;
        }

        .current,
        .active {
            a {
                border-left: 0;
                font-weight: bold;
            }
        }
    }
}

.l-off-canvas {
    background-color: #fff;

    .toggle-off-canvas {
        background-color: transparent;
    }

    .toggle-hamburger__bun {
        background-color: $color-background-color;
    }
}

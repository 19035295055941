.promo-strip-block {
    position: relative;

    &:before {
        position: absolute;
        top: 45px;
        right: 0;
        bottom: 0;
        left: 0;

        content: "";

        background-color: $grey-100;
        background-image:
          linear-gradient(
            to bottom,
            rgba(0,0,0,0.12),
            rgba(0,0,0,0) 8px
          );
    }
    .btn,
    .btn.active,
    .btn.active.focus,
    .btn.focus,
    .btn:active,
    .btn:active.focus,
    .dropdown-toggle,
    .form-control,
    a,
    button {
        &:focus {
            outline-color: #004685;
        }
    }
}

.promo-strip-block__row {
    margin-top: -45px;
    margin-right: -5px;
    margin-left: -5px;
    padding: 20px;

    background-color: $gold;
    @media only screen and (min-width: $width-lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-right: 0px;
        margin-left: 0px;

        > * {
            flex: 0 1 auto;
        }
    }
    @media only screen and (min-width: $width-lg) {
        padding: 20px 40px;
    }
    @media only screen and (min-width: 1400px) {
        padding: 20px 80px;
    }
}

.promo-strip-block__content {
    margin-bottom: 20px;

    text-align: center;

    .lead {
        color: $primary;

        font-family: $primaryFont;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.25;
        @media only screen and (min-width: $width-md) {
            font-size: 1.5rem;
        }
    }

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
    @media only screen and (min-width: $width-md) {
        padding-right: 20px;
    }
    @media only screen and (min-width: $width-lg) {
        margin-bottom: 0;

        text-align: left;
    }
}

.promo-strip-block__cta {
    text-align: center;

    .btn {
        display: block;

        + .btn {
            margin-top: 10px;
        }
    }
    @media only screen and (min-width: $width-sm) {
        .btn {
            display: inline-block;

            + .btn {
                margin-top: 0;
            }
        }
    }
    @media only screen and (min-width: $width-md) {
        flex-shrink: 0;
    }
    @media only screen and (min-width: $width-lg) {
        .btn {
            display: inline-block;

            + .btn {
                margin-top: 0;
            }
        }
    }
}


.table--comparision {
    thead {
        th {
            padding-bottom: 0.25rem;

            vertical-align: top;
        }
    }
    tbody {
        th, .table-cell-borderless {
            width: 16%;
        }
        td {
            width: 21%;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }
    .btn {
        margin-top: 8px;

        white-space: nowrap;
        letter-spacing: 0.01rem;
    }
}

.table-details {
    display: block;

    font-size: 12px;
    @media only screen and (min-width: $width-lg) {
        font-size: 18px;
    }
}
.table-legal {
    display: block;

    color: $grey-700;

    font-size: 9px;
    @media only screen and (min-width: $width-lg) {
        font-size: 12px;
    }
}
.table-cell-label {
    display: block;

    color: $grey-800;

    font-size: 12px;
    font-weight: 500;
}

.table--compare-mobile {
    position: relative;

    width: calc(100% + 10px);
    margin-right: -5px;
    margin-left: -5px;

    th, thead th {
        padding: 0;

        border-right: solid 20px #fff;
    }
    td {
        width: 50%;
        padding: 10px 0;

        border-right: solid 20px #fff;
    }
    .btn {
        width: 100%;
        margin-top: 20px;

        // white-space: nowrap;
        letter-spacing: 0.01rem;
    }
    .btn-link {
        padding-right: 0;
        padding-left: 0;
    }
    &:after {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 50%;

        display: block;

        width: 1px;
        height: 100%;

        content: '';

        background-color: $grey-600;
    }
}

.compare-form {
    .custom-select {
        height: 35px;
        padding: 5px 30px 5px 8px;

        font-size: 16px;
    }
}

.site-footer {
    padding-top: 30px;
    padding-right: 0;
    padding-right: env(safe-area-inset-right);
    padding-bottom: 10px;
    padding-left: 0;
    padding-left: env(safe-area-inset-left);

    color: #fff;
    background-color: $blue-dark;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
    }
    a {
        &:focus {
            outline-color: $blue-light;
        }
    }

    .container-fluid {
        padding-right: 10px;
        padding-left: 10px;
    }
    @media only screen and (min-width: $width-sm) {
        .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        .container-fluid {
            padding-right: 60px;
            padding-left: 60px;
        }
    }
    @media only screen and (min-width: 1400px) {
        .container-fluid {
            padding-right: 100px;
            padding-left: 100px;
        }
    }
}

.footer__logo {
    width: 100%;
    margin-bottom: 25px;
    padding-right: 10px;
    padding-left: 10px;

    text-align: center;

    img {
        width: 100%;
        max-width: 420px;
        height: auto;
    }
    @media only screen and (min-width: $width-lg) {
        padding-right: 0px;
        padding-left: 0px;

        text-align: left;
    }
}

.footer__social-bar {
    margin-bottom: 40px;

    text-align: center;

    font-size: 1.5rem;

    .social-link {
        margin-right: 7px;
        &:last-child {
            margin-right: 0px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        text-align: right;
    }
}

.footer__list {
    margin-bottom: 52px;
    padding-left: 0;

    list-style: none;

    li {
        margin-bottom: 13px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    @media only screen and (min-width: $width-lg) {
        margin-bottom: 30px;
    }
}

.footer__bottom__logo-group {
    margin-bottom: 20px;

    text-align: center;
    > * {
        margin-right: 25px;
        margin-bottom: 15px;
        &:last-child {
            margin-right: 0px;
        }
    }
    .logo-group__item {
        display: inline-block;

        margin-right: 11px;
        margin-bottom: 18px;
        margin-left: 11px;

        vertical-align: middle;

        img {
            width: auto;
            max-width: 100%;
            max-height: 34px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        margin-bottom: 0;

        text-align: left;
        .logo-group__item {
            margin-right: 25px;
            margin-left: 0px;
            &:last-child {
                margin-right: 0px;
            }
            img {
                max-height: 52px;
            }
        }
    }
}

.footer__bottom__nav {
    margin-bottom: 0.75rem;

    font-size: 15px;

    > * {
        margin-right: 4px;
        padding-right: 8px;

        border-right: solid 1px currentcolor;

        &:last-child {
            margin-right: 0px;
            padding-right: 0px;

            border-right: none;
        }
    }
}

.footer__bottom__numbers {
    @media only screen and (min-width: $width-lg) {
        text-align: right;
    }
}

.utility-nav {
    padding-left: 0;

    list-style: none;

    text-align: center;

    color: #fff;

    font-size: 14px;
    a {
        display: inline-block;

        padding: 10px 15px;

        color: #fff;
    }
    li {
        display: block;
    }
    > li > a {
        &:focus {
            outline-color: $blue-light;
        }
    }

    .btn-utility {
        //styles to make the button look like a link
        position: relative;

        padding: 10px 15px;

        letter-spacing: normal;
        text-transform: none;

        color: #fff;
        border: none;

        font-size: 14px;
        font-weight: 400;
        &:hover {
            text-decoration: underline !important;
        }
        &:focus {
            outline-color: $blue-light;
        }
        &:after {
            display: inline-block;

            width: 6px;
            height: 6px;
            margin-bottom: 3px;
            margin-left: 0.3rem;

            content: '';
            transform: rotate(45deg);

            border: solid 1px currentcolor;
            border-top: none;
            border-left: none;
        }
    }

    .dropdown-toggle {
        //styles to make the button look like a link
        padding: 10px;

        vertical-align: top;
        letter-spacing: normal;
        text-transform: none;

        color: #fff;
        border: none;

        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        &:hover {
            text-decoration: underline !important;
        }
        &:focus {
            outline-color: $blue-light;
        }
        &:after {
            width: 10px;
            height: 10px;
            margin-left: 0.5rem;

            transform: rotate(45deg);

            border: solid 3px currentcolor;
            border-top: none;
            border-left: none;
        }
        &[aria-expanded='true'] {
            &:after {
                margin-bottom: -5px;

                transform: rotate(-135deg);
            }
        }
    }
    .dropdown-menu {
        width: 100%;
        margin-top: 0;
        padding-top: 26px;
        padding-bottom: 26px;

        border: none;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
    }
    .dropdown-item {
        padding: 7px 20px;

        white-space: normal;

        color: $primary;
        &:hover {
            text-decoration: underline;

            background-color: transparent;
        }
        &.active, &:active {
            color: #fff;
            background-color: $primary;
        }
    }
    .dropdown-item--with-icon {
        position: relative;

        padding-left: 3rem;

        color: $blue;

        font-weight: 700;
        i {
            position: absolute;
            top: 8px;
            left: 1.5rem;
        }
    }
    .dropdown.dropdown--arrow {
        .dropdown-toggle {
            padding-right: 6px;
            padding-left: 6px;
            &:after {
                // display: none;
                width: 6px;
                height: 6px;
                margin-left: 0.3rem;

                border: solid 1px currentcolor;
                border-top: none;
                border-left: none;
            }
            &[aria-expanded='true'] {
                text-decoration: underline;
                &:after {
                    margin-bottom: -3px;
                }
                &:before {
                    position: absolute;
                    z-index: 1001;
                    bottom: -35px;
                    left: calc(50% - 14px);

                    display: block;

                    width: 28px;
                    height: 28px;

                    content: '';
                    transform: rotate(45deg);

                    background-color: #fff;
                }
            }
        }
        .dropdown-menu {
            width: calc(100vw - 10px);
            margin-top: 20px;
            &.show {
                &:after {
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    display: block;

                    content: '';

                    background-color: #fff;
                }
            }
        }
    }
    @media only screen and (min-width: $width-sm) {
        .dropdown.dropdown--arrow {
            .dropdown-menu {
                width: 220px;
                &.show {
                    &:before, &:after {
                        display: none;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: $width-lg) {
        position: absolute;
        top: 0px;
        right: 0px;

        margin-top: 14px;
        margin-bottom: 0px;
        a {
            padding: 0px 8px;
        }
        li {
            display: inline-block;
            &:last-child {
                a {
                    padding-right: 0;
                }
            }
        }
        .dropdown.dropdown--arrow {
            .dropdown-toggle {
                padding: 0px 8px;
            }
        }
    }
    @media only screen and (min-width: $width-xl) {
        a {
            padding: 0px 10px;
        }
        .dropdown.dropdown--arrow {
            .dropdown-toggle {
                padding: 0px 10px;
            }
        }
        li {
            margin-right: 17px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

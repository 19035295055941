.accordion-block {
    width: calc(100% + 10px);
    margin-right: -5px;
    margin-bottom: 10px;
    margin-left: -5px;

    .accordion > .card {
        overflow: visible;

        border: none;
        border-bottom: solid 1px $blue-light;

        &:focus-within {
            z-index: 1;
            // outline: 1px auto #757575;
            outline: 1px auto $blue-light;
            outline-style: solid;
            outline-offset: 0px;
        }

        .card-header {
            margin-bottom: 0;
        }
    }
    .accordion>.card:not(:first-of-type):not(:last-of-type) {
        border-bottom: solid 1px $blue-light;
    }
    .card-header {
        padding: 0;

        border-bottom: none;
        background-color: transparent;
    }
    .card-body {
        padding: 10px 10px 30px 55px;

        @extend .wysiwyg;
        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media only screen and (min-width: $width-sm) {
        margin-bottom: 20px;
    }

    @media only screen and (min-width: $width-lg) {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        .card-body {
            padding: 10px 105px 40px 135px;
        }
    }
}

.btn-accordion {
    position: relative;

    width: 100%;
    padding: 30px 10px 10px 40px;

    transition: all 0.3s;
    text-align: left;
    white-space: normal;
    text-transform: none;

    color: $primary;
    background-color: #fff;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;

    &:before {
        position: absolute;
        top: 40px;
        left: 10px;

        display: block;

        width: 12px;
        height: 2px;

        content: '';
        transition: all 0.3s;
        transform: rotate(-45deg);

        background-color: currentcolor;
    }
    &:after {
        position: absolute;
        top: 40px;
        left: 18px;

        display: block;

        width: 12px;
        height: 2px;

        content: '';
        transition: all 0.3s;
        transform: rotate(45deg);

        background-color: currentcolor;
    }

    &.collapsed {
        padding-bottom: 30px;

        background-color: $grey-100;
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    &:hover {
        text-decoration: underline !important;

        color: $primary;
    }
    &:focus {
        box-shadow: none;
        outline: none;
    }

    @media only screen and (min-width: $width-lg) {
        padding: 40px 105px 10px 105px;

        font-size: 20px;

        &:before {
            top: 50px;
            left: 40px;

            width: 15px;
            height: 3px;
        }
        &:after {
            top: 50px;
            left: 49px;

            width: 15px;
            height: 3px;
        }

        &.collapsed {
            padding-bottom: 40px;
        }
    }
}

.split-promo-block {
    margin-bottom: 10px;
    @media only screen and (min-width: $width-sm) {
        margin-bottom: 20px;
    }
    @media only screen and (min-width: $width-lg) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.split-promo-block--image-right {
    @media only screen and (min-width: $width-lg) {
        flex-direction: row-reverse;
    }
}

.split-promo-block__image {
    width: calc(100% + 10px);
    margin-right: -5px;
    margin-bottom: 15px;
    margin-left: -5px;

    background-color: $grey-300;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    img {
        width: 100%;
        height: auto;
        opacity: 0;
    }

    @media only screen and (min-width: $width-lg) {
        flex: 0 1 auto;

        width: calc(50% - 20px);
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
    }
}

.split-promo-block__body-wrap {
    @media only screen and (min-width: $width-lg) {
        flex: 0 1 auto;
        width: 50%;
        padding: 35px 40px 40px;
    }
    @media only screen and (min-width: $width-xl) {
        padding: 20px 8%;
    }
}

.split-promo-block__body {
    @media only screen and (min-width: $width-lg) {
    }
}

.split-promo-block__title {
    font-size: 1.5rem;
    @media only screen and (min-width: $width-lg) {
        font-size: 1.75rem;
    }
}

.split-promo-block__footer {
    margin-top: 30px;
    @media only screen and (min-width: $width-lg) {
    }
}

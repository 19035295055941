.sticky-notice {
    position: fixed;
    z-index: 1001;
    right: 0px;
    bottom: 0px;
    left: 0px;

    color: #fff;
    background-color: $blue;

    .close {
        position: absolute;
        top: 10px;
        right: 10px;

        color: #fff;
        &:focus {
            outline-color: $blue-light;
        }
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a:not(.btn),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-top: 0;
        color: #fff;
    }
    a:not(.btn) {
        text-decoration: underline;

        font-weight: 700;
    }
    .sticky-notice__row {
        padding: 50px 5px 20px;
    }
    .sticky-notice__body {
        position: relative;

        margin-bottom: 20px;
        padding-left: 55px;
        p {
            font-size: 14px;
        }
        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .sticky-notice__icon {
        position: absolute;
        top: 0px;
        left: 0px;

        width: 40px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .sticky-notice__cta {
        .btn {
            width: 100%;
        }
    }
    @media only screen and (min-width: $width-sm) {
        .sticky-notice__cta {
            padding-left: 55px;
            .btn {
                width: auto;
            }
        }
    }
    @media only screen and (min-width: $width-md) {
        .close {
            top: 15px;
            right: 8px;
        }
        .sticky-notice__row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            padding: 25px 50px 25px 0px;

            > * {
                flex: 0 1 auto;
            }
        }
        .sticky-notice__body {
            margin-bottom: 0px;
            padding-left: 60px;

            p {
                font-size: 16px;
            }
        }
        .sticky-notice__cta {
            padding-left: 30px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        .close {
            right: 50px;
        }
    }
    @media only screen and (min-width: 1400px) {
        .close {
            right: 90px;
        }
    }
}

.rates-block {
    margin-bottom: 30px;

    &:after {
        display: table;
        clear: both;

        content: "";
    }
    &:last-child {
        margin-bottom: 10px;
    }

    @media only screen and (min-width: $width-lg) {
        + .rates-block {
            margin-top: 85px;
        }
        &:last-child {
            margin-bottom: 20px;
        }
    }
}

.rates-summary {
    width: calc(100% + 40px);
    margin-right: -20px;
    margin-bottom: 10px;
    margin-left: -20px;
    padding: 23px 20px;

    background-color: $grey-100;
    @media only screen and (min-width: $width-lg) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        padding: 40px;
    }
}

.rates-summary__title {
    margin-bottom: 20px;

    font-size: 24px;
    @media only screen and (min-width: $width-lg) {
        font-size: 28px;
    }
}

.rates-summary__footer {
    margin-top: 20px;

    text-align: center;

    &:after {
        display: table;
        clear: both;

        content: "";
    }

    .btn {
        display: block;

        width: 100%;
    }
    .btn + .btn {
        margin-top: 10px;
    }
    .btn + .arrow-link {
        margin-top: 23px;
    }
    @media only screen and (min-width: $width-sm) {
        text-align: left;
        .btn, .arrow-link {
            display: inline-block;

            width: auto;
        }
        .btn + .arrow-link {
            margin-top: 0px;
            margin-left: 20px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        .btn, .arrow-link {
            float: left;
            clear: both;
        }
        .btn + .arrow-link {
            margin-top: 23px;
            margin-left: 0px;
        }
    }
}

.rates-table {
    width: calc(100% + 20px);
    margin-right: -10px;
    margin-left: -10px;

    .table {
        margin-bottom: 0;
        thead th {
            font-size: 14px;
        }
        th, td {
            font-size: 15px;
            width: 33.3333%;
        }
    }

    @media only screen and (min-width: $width-md) {
        .table {
            thead th {
                padding: 2.0rem .75rem 0.5rem;
            }
            th, td {
                font-size: 24px;
            }
        }
    }
    @media only screen and (min-width: $width-lg) {
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
    }
}

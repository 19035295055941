.testimonial-block {
    position: relative;

    overflow: hidden;

    width: calc(100% + 40px);
    margin-right: -20px;
    margin-left: -20px;

    &:before {
        position: absolute;
        z-index: 1;
        top: 0px;
        bottom: 0px;
        left: 0px;

        display: block;

        width: 40px;

        content: '';

        background-color: #fff;
    }

    @media only screen and (min-width: $width-md) {
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;

        &:before {
            display: none;
        }
    }
}

.testimonial-block__item {
    overflow: hidden;
}

.testimonial-block__quote {
    padding: 20px 15px 60px;

    color: #fff;
    background-color: $primary;
    background-image: url($imageDir+'waves-bkgnd01.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;

    -webkit-font-smoothing: antialiased;

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &:before {
        content: '“';
    }
    &:after {
        content: '”';
    }

    @media only screen and (min-width: $width-md) {
        padding: 60px 100px 96px;

        font-size: 2.25rem;
        line-height: 1.222;

        > p {
            &:last-child {
                display: inline;
            }
        }
        &:before {
            display: inline-block;

            width: 32px;
            height: 28px;
            margin: -17px 15px 17px -55px;

            content: '';

            background: transparent url($imageDir+'quote-open-gold.png')no-repeat center;
            background-size: contain;
        }
        &:after {
            display: inline-block;

            width: 32px;
            height: 28px;
            margin: 4px 0px -4px 15px;

            content: '';

            background: transparent url($imageDir+'quote-close-gold.png')no-repeat center;
            background-size: contain;
        }
    }
    @media only screen and (min-width: $width-xl) {
        padding-right: 210px;
        padding-left: 210px;
    }
}

.testimonial-block__attribution {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    padding: 10px 0px 10px 30px;
    > * {
        flex: 1 0 auto;
    }
    @media only screen and (min-width: $width-md) {
        padding-top: 15px;
        padding-left: 120px;
    }
    @media only screen and (min-width: $width-xl) {
        padding-left: 310px;
    }
}

.testimonial-block__image {
    position: relative;

    flex-grow: 0;

    width: 75px;
    height: 75px;

    border-radius: 75px;
    background-color: $grey-300;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    &:before, &:after {
        position: absolute;
        top: -40px;
        left: calc(50% + 12px);

        width: 2000px;
        height: 30px;

        content: '';
        transform: skewX(-38deg);

        background-color: #fff;
    }
    &:after {
        right: calc(50% + 12px);
        left: auto;

        transform: skewX(38deg);
    }
    @media only screen and (min-width: $width-md) {
        width: 100px;
        height: 100px;

        &:before, &:after {
            top: -51px;
            left: calc(50% + 14px);

            height: 36px;
        }
        &:after {
            right: calc(50% + 14px);
            left: auto;
        }
    }
    @media only screen and (min-width: $width-xl) {
    }
}

.testimonial-block__name {
    flex-shrink: 1;

    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 12px;

    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;

    @media only screen and (min-width: $width-md) {
        margin-top: 20px;
        margin-left: 20px;

        font-size: 24px;
    }
    @media only screen and (min-width: $width-xl) {
    }
}

.testimonial-block .owl-nav {
    display: none;
    .owl-prev, .owl-next {
        position: absolute;
        top: calc(50% - 113px);
        left: 27px;

        display: block;
        overflow: hidden;

        width: 60px;
        height: 60px;
        margin: 0;

        white-space: nowrap;
        text-indent: 200%;

        opacity: 0.8;

        &:after {
            position: absolute;
            top: calc(50% - 16px);
            left: calc(50% - 16px);

            display: block;

            width: 32px;
            height: 32px;

            content: '';
            transform: rotate(45deg);

            border: solid 6px #fff;
            border-top: none;
            border-right: none;
        }
        &:hover, &:focus {
            opacity: 1;
            background-color: transparent;
        }
    }
    .owl-next {
        right: 27px;
        left: auto;

        &:after {
            transform: rotate(225deg);
        }
    }
    @media only screen and (min-width: $width-md) {
        display: block;
    }
    @media only screen and (min-width: $width-xl) {
        .owl-prev {
            left: 54px;
        }
        .owl-next {
            right: 54px;
        }
    }
}

.testimonial-block .owl-dots {
    .owl-dot {
        button {
            overflow: hidden;

            width: 15px;
            height: 15px;
            padding: 0;

            white-space: nowrap;
            text-indent: 200%;

            border: none;
            border-radius: 15px;
            background-color: $grey-400;

            &:hover {
                background-color: $blue-light;
            }
        }
        + .owl-dot {
            margin-left: 11px;
        }
        &.active {
            button {
                background-color: $blue-light;
            }
        }
    }
    @media only screen and (min-width: $width-md) {
        display: none;
    }
}

.has-header-fixed {
    .l-header {
        padding-right: env(safe-area-inset-right);
        padding-left: env(safe-area-inset-left);
    }
    @media only screen and (min-width: $width-lg) {
        padding-top: 155px;
    }
}
.l-header, .l-off-canvas__helper {
    @media only screen and (min-width: $width-lg) {
        height: 155px;
    }
}
.l-off-canvas__helper {
    // padding-bottom: 0;
    // padding-bottom: env(safe-area-inset-bottom);
    // padding-left: 0;
    // padding-right: 0;
    // padding-left: env(safe-area-inset-left);
    // padding-right: env(safe-area-inset-right);
    // position: relative;
}
.l-header__elements {
    // background-color: $primary;
    @media only screen and (min-width: $width-lg) {
        height: 155px;
    }
}

.mln--navbar {
    height: auto;
}

.mln--navbar .mln__list>li {
    @media only screen and (min-width: 1400px) {
        margin-right: 1.4rem;
    }
}

.toggle-hamburger {
    width: 60px;
    margin-right: -15px;
    &[aria-expanded='true'] {
        margin-right: 0px;
        .toggle-hamburger__bun {
            width: 34px;
        }
    }
}

.l-off-canvas {
    padding-bottom: 140px;

    background-color: $primary;

    .toggle-hamburger__bun {
        background-color: #fff;
    }
    @media only screen and (min-width: $width-lg) {
        padding-bottom: 0px;
    }
}

.toggle-hamburger__bun {
    width: 26px;
    height: 4px;
    margin-bottom: 4px;
}
.js-off-canvas-showing .toggle-hamburger--to-x .toggle-hamburger__bun:last-child {
    margin-top: -8px;
}

.main-logo {
    position: absolute;
    z-index: 11;
    top: 0px;
    left: 0px;

    display: block;

    width: 150px;
    margin: 17px 14px;
    img {
        width: 100%;
        height: auto;
    }
    &:focus {
        outline-color: $blue-light;
    }

    @media only screen and (min-width: $width-lg) {
        width: 270px;
        margin: 38px 14px 38px 60px;
    }
    @media only screen and (min-width: $width-xl) {
        width: 308px;
        margin: 34px 14px 34px 60px;
    }
    @media only screen and (min-width: 1400px) {
        margin-left: 100px;
    }
}

// tab styles
.nav-pills {
    .nav-item {
        width: 50%;
    }
    .nav-link {
        position: relative;

        padding-top: 17px;
        padding-bottom: 16px;

        text-align: center;
        text-transform: uppercase;

        border: none;
        border-bottom: solid 5px $grey-400;
        background-color: $grey-200;

        &.active {
            color: $primary;
            border-bottom-color: $blue-light;
            background-color: #fff;

            font-weight: 700;
        }
        &:focus {
            outline-color: $blue-light;
            outline-offset: -1px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        justify-content: center;

        margin-right: 100px;

        .nav-item {
            width: auto;
            margin-top: 8px;
        }
        .nav-link {
            padding: 7px 5px 12px;

            color: #fff;
            border-bottom: 0;
            border-bottom-color: transparent;
            background-color: transparent;

            font-size: 14px;

            &.active {
                color: #fff;
                background-color: transparent;
                &:after {
                    left: 0px;

                    width: 100%;
                }
            }
            &:after {
                position: absolute;
                bottom: 0px;
                left: 50%;

                display: block;

                width: 0%;
                height: 5px;

                content: '';
                transition: all 0.2s;

                background-color: $blue-light;
            }
            &:hover {
                text-decoration: none;
                &:after {
                    left: 0px;

                    width: 100%;
                }
            }
            &:focus {
                outline-offset: 1px;
            }
        }
    }
    @media only screen and (min-width: $width-xl) {
        .nav-item {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
        .nav-link {
            padding: 7px 7px 12px;
        }
    }
}

// main nav
.personal-navbar, .business-navbar {
    .mln__child__mega-menu {
        display: none;

        background-color: #fff;
    }
    @media only screen and (min-width: $width-lg) {
        position: absolute;
        right: 60px;
        bottom: 0px;
        left: 0px;

        .mln__slide-over-controls, .mln__child__list {
            display: none;
        }

        .mln__list {
            float: right;

            .mln__has-child--mega-menu > .mln__child__collapse {
                width: calc(100% + 60px);

                box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
            }
        }

        .mln__child__mega-menu {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: center;

            padding: 30px;

            > * {
                flex: 0 1 auto;
            }
            .mm__column {
                width: calc(20% - 30px);
                margin-right: 30px;

                text-transform: none;

                > * {
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
                &.mm__column--full-width {
                    width: 100%;
                    margin-right: 0px;
                    margin-bottom: 30px;
                }
                &:last-child {
                    width: 20%;
                    margin-right: 0px;
                }
            }
            .mm__heading {
                display: block;

                margin-bottom: 15px;

                text-transform: uppercase;

                color: $grey-900;

                font-weight: 700;
                line-height: 1.4;

                a {
                    display: inline;

                    transition: background-size 0.2s;

                    color: $primary;
                    background-image: linear-gradient(to left,$blue-light 0,$blue-light 100%);
                    background-repeat: repeat-x;
                    background-position: 0 1rem;
                    background-size: 100% 0rem;
                    &:hover {
                        text-decoration: none;

                        background-size: 100% .2rem;
                    }
                }
            }
            .mm__list {
                padding-left: 0;

                list-style: none;
                li {
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
                a {
                    display: inline;

                    transition: background-size 0.2s;

                    background-image: linear-gradient(to left,$blue-light 0,$blue-light 100%);
                    background-repeat: repeat-x;
                    background-position: 0 1rem;
                    background-size: 100% 0rem;
                    &:hover {
                        text-decoration: none;

                        background-size: 100% .2rem;
                    }
                }
            }
        }
    }
}

.mln--slide-over {
    @media only screen and (min-width: $width-lg) {
    }
}

.mm-cta-block {
    width: 100%;
    margin-bottom: 30px;
    &.mm-cta-block--border-left {
        padding-left: 30px;

        border-left: solid 1px $dark-tint;
    }
}
.mm-cta-block__image {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
}
.mm-cta-block__body {

}
.mm-cta-block__title {
    letter-spacing: 0.02rem;

    font-family: $primaryFont;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.222;
}
.mm-cta-block__footer {
    text-align: right;
}

.mln__child-controls a, .mln__list>li>a, .mln__toggle-link, [class*=" mln__level-"]>a, [class^=mln__level-]>a {
    padding-top: 18px;
    padding-bottom: 18px;

    color: $primary;
    border-bottom: solid 1px $dark-tint;
    background-color: #fff;

    &:focus {
        outline-offset: -1px;
    }

    @media only screen and (min-width: $width-lg) {
        padding-top: 12px;
        padding-bottom: 12px;

        color: #fff;
        border-bottom: none;
        background-color: transparent;
    }
}

.mln__toggle-btn, .mln__toggle-indicator {
    color: currentcolor;
}

.mln__toggle-btn__chevron:after, .mln__toggle-btn__chevron:before {
    width: 0.8rem;

    border-color: currentcolor;
    border-top-width: 4px;
}
.mln__toggle-btn__chevron:before {
    left: -2px;
}
.mln__toggle-btn__chevron:after {
    right: -6px;
}

.toggle-off-canvas {
    &:focus {
        outline-color: $blue-light;
        outline-offset: -1px;
    }
}

.mln__slide-over-controls {
    // display: flex;
    // align-items: center;
    // padding: 13px 1rem;
    padding: 0;
    // > * {
    //     flex: 0 1 auto;
    // }
}

.mln__back-btn {
    position: relative;

    display: block;
    overflow: hidden;

    width: 100%;
    margin-right: 20px;
    margin-bottom: 0;
    padding: 18px 1rem;

    text-align: left;

    color: #fff;

    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;

    @extend .btn;

    &:before {
        position: relative;

        display: inline-block;

        width: 12px;
        height: 12px;
        margin-right: 14px;
        margin-left: 3px;

        content: '';
        transform: rotate(135deg);

        border: solid 4px currentcolor;
        border-top: none;
        border-left: none;
    }
    &:hover {
        color: #fff;
    }
    &:focus {
        outline-color: $blue-light;
        outline-offset: -1px;
    }
}

.mln__slide-over-title {
    display: block;

    width: 100%;
    padding: 18px 1rem;

    text-transform: none;

    color: $primary;
    border-bottom: solid 1px rgba(0,0,0,.18);
    background-color: #fff;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    &:hover {
        color: $primary;
    }
    &:focus {
        outline-offset: -1px;
    }
    &[href="#"] {
        display: none;
    }
}

.mln__level-1 {
    text-transform: uppercase;
    > a, > .mln__child-controls > a {
    }
    @media only screen and (min-width: $width-lg) {
        > a, > .mln__child-controls > a {
            position: relative;

            padding-right: 1rem;
            &:after {
                position: absolute;
                bottom: 0px;
                left: 50%;

                display: block;

                width: 0%;
                height: 5px;

                content: '';
                transition: all 0.2s;

                background-color: $blue-light;
            }
            &:hover {
                text-decoration: none;
                &:after {
                    left: 0px;

                    width: 100%;
                }
            }
            > .mln__toggle-indicator {
                display: none;
            }
            &[aria-expanded="true"] {
                outline: none;
                &:after {
                    left: 0px;

                    width: 100%;
                }
            }
            &:focus {
                outline-color: $blue-light;
            }
        }
        .mln__toggle-btn {
            display: none;
        }
    }
}

.mln__child__list > li > a {
    text-transform: none;
}

.sign-in-mobile {
    position: absolute;
    top: 0px;
    right: 65px;

    padding-right: 0px;
    padding-left: 0px;

    @extend .account-nav;

    @media only screen and (min-width: $width-lg) {
        display: none;
    }
}

#searchMobile {
    flex-direction: row-reverse;

    margin-bottom: 0px;

    border-bottom: solid 1px $dark-tint;
    .form-control {
        height: 60px;
        padding-left: 5px;

        border-color: #fff;

        font-size: 18px;
    }
    .btn-search {
        overflow: hidden;

        width: 50px;
        padding-right: 10px;

        white-space: nowrap;
        text-indent: 9000px;

        background: #fff url($imageDir+'icon-search.png') no-repeat;
        background-position: center right 10px;
        background-size: 20px;

        font-size: 1.25rem;
        &:before {
            display: none;
        }
        &:focus {
            outline: 1px auto $blue-light;
            outline-style: solid;
            outline-offset: -1px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        display: none;
    }
}

#searchCollapseBtn {
    position: absolute;
    right: 0px;
    bottom: 0px;

    display: none;
    overflow: hidden;

    width: 48px;
    height: 48px;
    padding: 10px;

    white-space: nowrap;
    text-indent: 9000px;

    color: #fff;

    &:hover, &:active {
        border-color: $primary;
        background-color: $primary;
    }
    &:focus {
        outline-color: $blue-light;
        outline-offset: -1px;
    }

    &:before, &:after {
        position: absolute;
        top: calc(50% - 2px);
        left: calc(50% - 15px);

        display: block;

        width: 30px;
        height: 4px;

        content: '';
        transform: rotate(-45deg);

        border: solid 2px #fff;
        border-radius: 4px;
    }
    &:after {
        transform: rotate(45deg);
    }

    &.collapsed {
        &:before {
            position: absolute;
            top: calc(50% - 13px);
            left: calc(50% - 13px);

            display: block;

            width: 20px;
            height: 20px;

            content: '';

            border: solid 2px #fff;
            border-radius: 10px;
        }
        &:after {
            position: absolute;
            top: calc(50% + 6px);
            left: calc(50% + 2px);

            display: block;

            width: 11px;
            height: 2px;

            content: '';
            transform: rotate(45deg);

            border: solid 1px #fff;
        }
    }
    @media only screen and (min-width: $width-lg) {
        display: block;
    }
}

#searchCollapse {
    position: absolute;
    top: 100%;
    right: 0px;
    left: 0px;

    transition: box-shadow 0.3s;
    // display: none;

    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);

    &:focus-within {
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.75);
    }
    .input-group {
        margin-bottom: 0;
        input[type="text"] {
            &:focus {
                border-color: #fff;
                box-shadow: none;
            }
        }
    }
    .form-control {
        height: 80px;
        padding: 15px 30px;

        border-color: #fff;
        // color: $grey-400;

        font-size: 2rem;
        font-style: italic;
    }
    .btn-search-shy {
        position: absolute;
        top: 0px;
        right: 0px;

        overflow: hidden;

        width: 80px;
        height: 80px;

        transition: opacity 0.3s;
        white-space: nowrap;
        text-indent: 9000px;

        opacity: 0;
        background: #fff url($imageDir+'icon-search.png') no-repeat;
        background-position: center;
        background-size: 30px;

        font-size: 1.25rem;
        &:focus, &:hover {
            opacity: 1;
        }
        &:focus {
            // outline: 1px auto #faa61a;
            // outline-style: solid;
            outline-offset: -1;
            // box-shadow: 0 0 0 0.2rem rgba(254,208,63,.25);
        }
    }
    @media only screen and (min-width: $width-lg) {
        // display: block;
    }
}

#offCanvasTitle {
    color: #fff;
}

.sign-in-hidden-mobile {
    @media only screen and (max-width: $width-lg) {
        display: none;
    }
}

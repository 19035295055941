.featured-content-block {
    position: relative;

    width: calc(100% + 10px);
    margin-right: -5px;
    margin-bottom: 10px;
    margin-left: -5px;
    padding: 20px;

    transition: box-shadow 0.3s;
    text-align: left;

    border: solid 1px $grey-300;
    background-color: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    &:after {
        display: table;
        clear: both;

        content: "";
    }
    @media only screen and (min-width: $width-sm) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-left: 0px;
    }
    @media only screen and (min-width: $width-lg) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content-block__image {
        overflow: hidden;

        width: 100%;
        margin: 0px 0px 15px;

        background-color: $grey-300;
        background-repeat: no-repeat;
        background-position: center;
        // background-image: url($imageDir+'thumbnail02.jpg');
        background-size: cover;

        img {
            width: 100%;
            height: auto;

            opacity: 0;
        }
        @media only screen and (min-width: $width-sm) {
            margin: 0px 0px 15px;
        }
        @media only screen and (min-width: $width-lg) {
            align-self: stretch;
            flex: 0 1 auto;

            width: calc(50% - 10px);
            margin-right: 10px;
            margin-bottom: 0px;
        }
    }

    .content-block__body-wrap {
        @media only screen and (min-width: $width-lg) {
            flex: 0 1 auto;

            width: 50%;
            padding: 35px 30px 40px 35px;
        }
        @media only screen and (min-width: $width-xl) {
            padding: 40px 40px 44px 50px;
        }
    }

    .content-block__body {
        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
        @media only screen and (min-width: $width-lg) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .content-block__title {
        font-size: 1.25rem;
        a {
            color: $grey-800;
        }
        @media only screen and (min-width: $width-lg) {
            margin-top: 25px;
            margin-bottom: 25px;

            font-size: 1.5rem;
        }
    }

    .content-block__footer {
        margin-top: 30px;
        .btn {
            width: 100%;
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 0px;
            }
        }
        @media only screen and (min-width: $width-sm) {
            .btn {
                width: auto;
                margin-bottom: 0;
            }
        }
        @media only screen and (min-width: $width-lg) {
            margin-top: 0.75rem;
        }
    }
}

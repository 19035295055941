.footnote-block {
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    @media only screen and (min-width: $width-md) {
        padding-top: 35px;
        padding-bottom: 30px;
    }
}

.footnote-block__body {
    color: $grey-800;

    font-size: 10px;
    p {
        color: $grey-800;

        font-size: 10px;
    }
    ul, ol {
        padding-left: 2em;

        font-size: 10px;
        font-weight: 300;
        li {
            margin-bottom: 0.55rem;
            padding-left: 5px;
        }
    }
    > *:last-child {
        margin-bottom: 0px;
    }
    @media only screen and (min-width: $width-md) {
        font-size: 12px;
        p {
            font-size: 12px;
        }
        ul, ol {
            font-size: 12px;
            li {
                padding-left: 10px;
            }
        }
    }
}

.hero {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    color: #fff;
    background: $blue url($imageDir+'hero-texture.jpg')no-repeat top center;
    background-size: cover;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a:not(.btn),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    h1, .h1 {
        &:after {
            display: block;

            width: 120px;
            height: 5px;
            margin-top: 18px;

            content: '';

            background-color: $gold;
        }
    }
    .eyebrow {
        &:before {
            background-color: $gold;
        }
    }
    .hero__footer {
        margin-top: 1.15rem;
        .btn {
            width: 100%;
            display: block;
        }
        .btn + .btn {
            margin-top: 8px;
        }
    }

    @media only screen and (min-width: $width-sm) {
        .hero__footer {
            .btn {
                width: auto;
                display: inline-block;
            }
            .btn + .btn {
                margin-top: 0px;
                margin-left: 15px;
            }
            .btn + .btn-link, .btn + .btn-link-white, .btn-link + .btn, .btn-link-white + .btn {
                margin-left: 0px;
            }
        }
    }

    @media only screen and (min-width: $width-md) {
        .hero__footer {
            margin-top: 2rem;
        }
    }

    @media only screen and (min-width: $width-lg) {
        padding-top: 60px;
        padding-bottom: 60px;

        .eyebrow {
            font-size: 14px;
        }
    }
}

.hero__background {
    display: none;
}

.hero--image {
    padding-top: 0px;
    padding-bottom: 0px;

    background-color: #fff;
    background-image: none;

    .hero__background {
        display: block;

        width: 100%;
        height: 38vh;
        max-height: 360px;

        background: $blue url($imageDir+'hero-texture.jpg')no-repeat top center;
        background-position-x: 82%;
        background-size: cover;
    }
    .hero__content {
        margin-top: -55px;
        margin-right: -5px;
        margin-left: -5px;
        padding: 40px 15px 25px;

        color: $grey-800;
        background-color: rgba(255,255,255,0.95);

        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $grey-800;
        }
        a:not(.btn) {
            color: $primary;
        }
        .btn {
            display: block;

            margin-bottom: 0.75rem;
        }
    }
    .eyebrow {
        &:before {
            background-color: $blue-light;
        }
    }
    @media only screen and (min-width: $width-sm) {
        .hero__content {
            .btn {
                display: inline-block;
            }
        }
    }
    @media only screen and (min-width: $width-md) {
        .hero__content {
            padding: 40px 40px;
        }
    }
    @media only screen and (max-width: $width-lg) and (orientation: landscape) {
        .hero__background {
            min-height: 220px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        overflow: hidden;

        .hero__background {
            height: auto;
            min-height: 500px;
            padding-top: 500px;
            background-position-x: 62%;
        }
        .hero__content {
            position: absolute;
            bottom: 0px;

            width: calc(60% - 115px);
            min-height: calc(500px - 40px);
            margin-top: 0px;
            margin-right: 0px;
            margin-left: 0px;
            padding: 40px 10% 28px 40px;
        }
    }
    @media only screen and (min-width: $width-xl) {
        .hero__background {
            background-position-x: 75%;
        }
        .hero__content {
            width: calc(50% - 115px);
        }
    }
    @media only screen and (min-width: 1800px) {
        .hero__content {
            // min-height: calc(33.25vw - 40px);
            min-height: calc(27.75vw - 40px);
        }
        .hero__background {
            padding-top: 27.75%;
        }
    }
    @media only screen and (min-width: 2500px) {
        .hero__content {
            min-height: calc(700px - 40px);
        }
        .hero__background {
            padding-top: 700px;
        }
    }
}

.contact-block {

}

.contact-block__body {
    margin-bottom: 40px;
    .icon-link {
        // margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @extend .h6;
        a {
            text-decoration: none;
        }
    }

    .inline-icon {
        display: inline-block;

        width: auto;
        height: 32px;
        margin-right: 15px;

        vertical-align: middle;
    }
    @media only screen and (min-width: $width-lg) {
        margin-bottom: 0px;
        padding-right: 15%;
        p {
            margin-bottom: 1.5rem;
        }
    }
}

.contact-block__title {
    font-size: 1.75rem;
    @media only screen and (min-width: $width-lg) {
        font-size: 2rem;
    }
}

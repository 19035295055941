// styles for single click card functionality
.card-block:hover {
    cursor: pointer;

    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
}
.card-block a {
    text-decoration: none;
}
.card-block a:focus {
    text-decoration: underline;
}
.card-block:focus-within {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
}
.card-block:focus-within a:focus {
    text-decoration: none;
}

.card-block {
    position: relative;

    overflow: hidden;

    width: calc(100% + 10px);
    margin-right: -5px;
    margin-bottom: 10px;
    margin-left: -5px;
    padding: 20px;

    transition: box-shadow 0.3s;
    text-align: left;

    border: solid 1px $grey-300;
    background-color: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    &:after {
        display: table;
        clear: both;

        content: "";
    }
    @media only screen and (min-width: $width-sm) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-left: 0px;
        padding: 20px 30px;
    }
}

.card-block__image {
    width: calc(100% + 20px);
    margin: -10px -10px 15px;
    img {
        width: 100%;
        height: auto;
    }
    @media only screen and (min-width: $width-sm) {
        width: calc(100% + 40px);
        margin: -10px -20px 15px;
    }
}

.card-block__date {
    position: absolute;
    top: 0px;
    right: 20px;

    display: block;

    width: 50px;
    height: 54px;

    text-align: center;

    color: $primary;
    border: solid 1px $blue-light;
    border-top: none;
    background-color: $blue-xlight;

    @media only screen and (min-width: $width-lg) {
        width: 72px;
        height: 76px;
    }
}

.card-block__date__day {
    display: inline-block;

    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
    @media only screen and (min-width: $width-lg) {
        font-size: 32px;
        line-height: 1.6;
    }
}

.card-block__date__month {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;

    display: block;

    padding-top: 6px;
    padding-bottom: 4px;

    text-transform: uppercase;

    color: #fff;
    background-color: $blue-light;

    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    @media only screen and (min-width: $width-lg) {
        padding-top: 7px;
        padding-bottom: 4px;

        font-size: 16px;
    }
}

.card-block__body {
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
    @media only screen and (min-width: $width-lg) {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.card-block__title {
    font-size: 1.25rem;
    a {
        color: $grey-800;
    }
    @media only screen and (min-width: $width-lg) {
        // margin-bottom: 30px;
    }
}

.card-block__footer {
    margin-top: 20px;
    .btn {
        width: 100%;
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 0px;
        }
    }
    @media only screen and (min-width: $width-sm) {
        .btn {
            width: auto;
            margin-bottom: 0;
        }
    }
    @media only screen and (min-width: $width-lg) {
        margin-top: 0.75rem;
    }
}

.card-block--news {
    .card-block__title {
        font-size: 1.125rem;
    }
    @media only screen and (min-width: $width-sm) {
        padding: 20px;
    }
    @media only screen and (min-width: $width-lg) {
        .card-block__title {
            font-size: 1.25rem;
        }
    }
}

.card-block--event {
    .eyebrow {
        padding-right: 60px;
    }
    .card-block__title {
        font-size: 1.125rem;
    }
    @media only screen and (min-width: $width-sm) {
        padding: 20px;
    }
    @media only screen and (min-width: $width-lg) {
        .eyebrow {
            padding-right: 0px;
        }
        .card-block__body {
            padding-right: 90px;
        }
        .card-block__title {
            font-size: 1.25rem;
        }
    }
}

.card-block--featured-event {
    .card-block__image {
        width: 100%;
        margin: 0px 0px 15px;
    }
    .card-block__date {
        top: 20px;
        right: 40px;
    }
    .card-block__title {
        font-size: 1.25rem;
    }
    @media only screen and (min-width: $width-sm) {
        padding: 20px;
    }
    @media only screen and (min-width: $width-lg) {
        height: calc(100% - 20px);

        .card-block__title {
            margin-bottom: 30px;

            font-size: 1.5rem;
        }
    }
}

//
// Mixins
//

// Aspect ratio
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

// Multiply levels of an element
@mixin level-padding-left-multiplier($sel, $levels-deep, $inset) {
	$selOrig: $sel;

	@for $i from 1 to ($levels-deep + 1) {
		#{$sel + $i} {
			> a,
			.mln__child-controls > a {
				padding-left: $inset * $i;
			}
		}
    }
}


@mixin background-gradient($startColor, $endColor, $orientation) {
	background: $startColor;

    @if $orientation == 'vertical' {
		background: -webkit-linear-gradient(top, $startColor, $endColor);
		background: linear-gradient(to bottom, $startColor, $endColor);
    } @else if $orientation == 'horizontal' {
		background: -webkit-linear-gradient(left, $startColor, $endColor);
		background: linear-gradient(to right, $startColor, $endColor);
    } @else {
		background: -webkit-radial-gradient(center, ellipse cover, $startColor, $endColor);
		background: radial-gradient(ellipse at center, $startColor, $endColor);
    }
}

//
// Slideover related
//

.mln__slide-over-title {
    color: $color-slide-over-slide-title;
    font-size: $font-size-slide-title;
    display: block;
}

.mln__back-btn {
    margin-bottom: $margin-bottom-back-button;
}

.mln__slide-over-controls {
    padding: $padding-menu-item-inset;

    @media (min-width: $mln-breakpoint) {
        display: none;
    }
}

.mln--slide-over {
    @media (max-width: $mln-breakpoint - 1) {
        overflow: hidden;
        min-height: 1px;
        transition: min-height $transition-speed-slide-over-height $transition-ease-slide-over-height;
        // min-height: calc(100vh - #{$height-navbar}) !important;
        
        .mln__list,
        .mln__child__list {
            width: $width-slide-over-width;
        }
        
        [class*="mln__level-"] > a,
        [class*="mln__level-"] .mln__child-controls > a {
            padding-left: $padding-menu-item-inset;
        }
        
        .mln__toggle-btn__chevron,
        .mln__toggle-btn__symbol-custom {
            transform: rotate(-90deg);
            display: inline-block;
        }
            
        // .mln__child__collapse__helper {
        //     min-height: calc(100vh - #{$height-navbar}) !important;
        // }

        .mln__child__collapse {
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
            transition: transform $transition-speed-child-menu cubic-bezier(.19, 1, .22, 1);
            transform: translateX($width-slide-over-width);
            background-color: $color-menu-primary;
            height: 0 !important;
        }

        .mln__has-child--showing {
            > .mln__child__collapse {
                height: auto !important;
                min-height: 100%;
                width: $width-slide-over-width;
                transform: translateX(0);
            }
        }
        
        .mln__child--transitioning {
            height: auto !important;
        }
    }
}
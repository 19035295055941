// Full width wrappers, used for template layout

.wrap {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;

    &:after {
        display: table;
        clear: both;

        content: "";
    }

    @media only screen and (min-width: $width-lg) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.wrap--tan {
    background-color: $gold;;
}

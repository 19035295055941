/* ==========================================================================
   base SCSS
   ========================================================================== */
//default type styles

html {
    height: auto;

    text-align: center;

    font-size: 16px;
    @media only screen and (min-width: 1024px) {
    }
    @media only screen and (min-width: 1440px) {
    }
    @media only screen and (min-width: 1600px) {
    }
}

body {
    display: block;

    width: 100%;
    min-width: 320px;
    height: auto;
    margin: auto;
    padding-top: 75px;

    text-align: left;

    color: $grey-700;
    background-color: $primary;

    font-family: $primaryFont;
    @media only screen and (min-width: $width-xl) {
        padding-top: 120px;
    }
}

a p{
    margin-bottom: 0px;
}

p {
    margin-top: 0px;
    margin-bottom: 0.75rem;

    line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    &:first-child {
        margin-top: 0px;
    }
    + h1, + h2, + h3, + h4, + h5, + h6 {
        margin-top: 0.25rem;
    }
    + .meta {
        margin-top: 0;
    }
}

h1 {
    margin-bottom: 22px;

    font-family: $primaryFont;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.222;

    @media only screen and (min-width: $width-xl) {
        font-size: 2.5rem;
    }
}

h2 {
    font-family: $primaryFont;
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 1.222;

    @media only screen and (min-width: $width-xl) {
        font-size: 2.25rem;
    }
}

h3 {
    color: $grey-800;

    font-family: $primaryFont;
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1.222;

    @media only screen and (min-width: $width-xl) {
        font-size: 2rem;
    }
}

h4 {
    color: $grey-800;

    font-family: $primaryFont;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.222;

    @media only screen and (min-width: $width-xl) {
        font-size: 1.75rem;
    }
}

h5 {
    font-family: $primaryFont;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.222;
    @media only screen and (min-width: $width-xl) {
        font-size: 1.5rem;
    }
}

h6 {
    font-family: $primaryFont;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.222;
    @media only screen and (min-width: $width-xl) {
        font-size: 1.25rem;
    }
}

// class-based heading styles
.h1 {
    font-size: 2rem !important;

    @extend h1;
    @media only screen and (min-width: $width-xl) {
        font-size: 2.5rem !important;
    }
}

.h2 {
    font-size: 1.875rem !important;

    @extend h2;
    @media only screen and (min-width: $width-xl) {
        font-size: 2.25rem !important;
    }
}

.h3 {
    font-size: 1.75rem !important;

    @extend h3;
    @media only screen and (min-width: $width-xl) {
        font-size: 2rem !important;
    }
}

.h4 {
    font-size: 1.5rem !important;

    @extend h4;
    @media only screen and (min-width: $width-xl) {
        font-size: 1.75rem !important;
    }
}

.h5 {
    font-size: 1.25rem !important;

    @extend h5;
    @media only screen and (min-width: $width-xl) {
        font-size: 1.5rem !important;
    }
}

.h6 {
    font-size: 1.125rem !important;

    @extend h6;
    @media only screen and (min-width: $width-xl) {
        font-size: 1.25rem !important;
    }
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

img {
    max-width: 100%;
    height: auto;
}

code {
}

hr {
    clear: both;

    margin: 1.5rem 0;

    border-top: 1px solid $blue;
}

// remove extra gap when margins can't overlap
.row + hr {
    margin-top: 1.3rem;
}

.blockquote,
blockquote {
    position: relative;

    display: block;

    max-width: 610px;
    margin: 3rem auto;

    color: $blue-light;

    font-size: 1.875rem;
    font-weight: 300;
    line-height: 1.3;

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .blockquote-footer {
        color: $blue-light;
    }

    &:before {
        position: absolute;
        top: -13px;
        left: -5px;

        display: block;

        width: 18px;
        height: 14px;

        content: '';

        background: transparent url($imageDir+'quote-open.png')no-repeat center;
        background-size: contain;
    }
    &:after {
        position: absolute;
        right: -5px;
        bottom: -13px;

        display: block;

        width: 18px;
        height: 14px;

        content: '';

        background: transparent url($imageDir+'quote-close.png')no-repeat center;
        background-size: contain;
    }

    @media only screen and (min-width: $width-md) {
        font-size: 2.25rem;

        > p {
            &:last-child {
                display: inline;
            }
        }
        &:before {
            top: -4px;
            left: -50px;

            width: 32px;
            height: 28px;

        }
        &:after {
            right: auto;
            bottom: auto;

            display: inline-block;

            width: 32px;
            height: 28px;
            margin-top: 22px;
            margin-left: 30px;
        }
    }
}

dl {
    position: relative;

    margin-bottom: 0.75rem;

    font-size: 0.875rem;
    dt {
    }
    dd {
        margin-bottom: 0.75rem;
    }
    @media only screen and (min-width: $width-md) {
        dt {
            display: inline-block;

            width: calc(33% - 30px);
            margin-right: 30px;
            margin-bottom: 0.75rem;
        }
        dd {
            display: inline-block;

            width: calc(66%);
        }
    }
    @media only screen and (min-width: $width-lg) {
        font-size: 1rem;
    }
}

b, strong {
    font-weight: 700;
}

// styling focus ring --------------------------------------------------------

.btn,
.btn.active,
.btn.active.focus,
.btn.focus,
.btn:active,
.btn:active.focus,
.dropdown-toggle,
.form-control,
.jquery-background-video-pauseplay,
.mln__toggle-btn,
.navbar-toggle,
a,
button {
    &:focus {
        outline: 1px auto #757575;
        outline-style: solid;
        outline-offset: 1px;
    }
}

// extra type classes --------------------------------------------------------
.lead {
    margin-bottom: 2.15rem;

    font-family: $primaryFont;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.3333;

    -webkit-font-smoothing: antialiased;
    @media only screen and (min-width: $width-md) {
        font-size: 1.5rem;
    }
}
ul.lead {
    li {
        margin-bottom: 0.75rem;
    }
}

.meta {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;

    letter-spacing: normal;

    color: $grey-800;

    font-family: $primaryFont;
    font-size: 0.75rem;
    font-weight: 400;
    a {
        text-decoration: underline;

        font-weight: 700;
    }
}

.legal {
    color: $grey-800;

    font-size: 10px;
    font-weight: 300;
    @media only screen and (min-width: $width-md) {
        font-size: 12px;
    }
}

.eyebrow, .category-name {
    display: block;

    margin-bottom: 0.75rem;

    letter-spacing: 0.05rem;
    text-transform: uppercase;

    font-size: 14px;
    font-weight: 700;
    &:before {
        display: inline-block;

        width: 8px;
        height: 8px;
        margin-right: 12px;

        content: '';

        border-radius: 4px;
        background-color: $blue-light;
    }
    @media only screen and (min-width: $width-lg) {
        font-size: 16px;
        &:before {
            width: 10px;
            height: 10px;

            border-radius: 5px;
        }
    }
}

.eyebrow + *, .category-name + * {
    margin-top: 0px;
}

.category-name {
    color: $blue-light;
    &:before {
        display: none;
    }
}

.display-1 {
    font-size: 2rem;
    font-weight: 300;

    -webkit-font-smoothing: antialiased;

    b, strong {
        font-weight: 500;
    }

    @media only screen and (min-width: $width-md) {
        font-size: 2.75rem;
    }
    @media only screen and (min-width: 1400px) {
        font-size: 3.75rem;
        line-height: 1.1;
    }
}

.display-2 {

}

.display-3 {

}

.display-4 {

}
// table style modifiers -----------------------------------------------------
.table {
    margin-bottom: 0.75rem;

    text-align: center;
    thead th {
        text-transform: uppercase;

        color: $blue;
        border: none;

        font-size: 14px;
        font-weight: 700;
    }
    thead td {
        border: none;
    }
    th, td {
        padding: 1.25rem 0.5rem;

        border-top: solid 1px $blue;
        border-right: solid 1px $blue;

        font-size: 14px;

        -webkit-font-smoothing: antialiased;
        &:last-child {
            border-right: none;
        }
    }
    tbody tr {
        th {
            border-top: none;
            border-right: solid 20px #fff;
            background-color: $grey-100;

            font-size: 16px;
            font-weight: 700;
            line-height: 1.35;
        }
        &:first-child {
            th, td {
                border-top: none;
            }
        }
    }
    .table-cell-borderless {
        border-top: none;
        border-right: none;
    }
    .btn + .btn {
        margin-top: 8px;
    }
    @media only screen and (min-width: $width-md) {
        th, td {
            padding: 1.35rem 0.75rem;

            border-right: none;

            font-size: 24px;
            font-weight: 300;
        }
    }
    @media only screen and (min-width: $width-xl) {
        tbody tr {
            th {
                font-size: 18px;
            }
        }
    }
}
caption {
    caption-side: bottom;

    text-align: right;

    @extend .legal;
}

.table-responsive {
    &::-webkit-scrollbar {
      width: 20px;
      height: 20px;
    }

    &::-webkit-scrollbar-track {
        border: solid 1px $grey-400;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      outline: none;
      background-color: $blue-light;
    }
}


// main content area style overrides -----------------------------------------
.wysiwyg {
    a {
        text-decoration: underline;
    }
    ul, ol {
        overflow: hidden;

        margin-bottom: 0.75rem;
        padding-left: 26px;

        font-size: 0.875rem;
        li {
            position: relative;

            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            > ul, > ol {
                margin-top: 0.5rem;
                margin-bottom: 0;
            }
        }
        @media only screen and (min-width: $width-lg) {
            font-size: 1rem;
        }
    }
}

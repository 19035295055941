.btn {
    padding: 8px 15px 7px;

    text-decoration: none !important;
    letter-spacing: 0.055rem;
    text-transform: uppercase;

    border-width: 1px;

    font-family: $primaryFont;
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.15;

    -webkit-appearance: none;

    &:focus {
        box-shadow: none;
        &:active {
            box-shadow: none !important;
        }
    }

    @media only screen and (min-width: $width-lg) {
        padding: 15px 20px;
    }
}

.btn-lg {
    &:after {
        display: inline-block;

        width: 9px;
        height: 9px;
        margin-bottom: 1px;
        margin-left: 10px;

        content: '';
        transform: rotate(45deg);

        border-top: solid 3px currentcolor;
        border-right: solid 3px currentcolor;
    }
    @media only screen and (min-width: $width-lg) {
        padding: 30px 20px;
    }
}

.btn-row {
    display: block;

    margin-top: 20px;

    text-align: center;

    .btn {
        display: block;

        width: 100%;
        margin-bottom: 20px;
    }
    @media only screen and (min-width: $width-sm) {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            display: inline-block;
            flex: 0 1 auto;

            width: calc(50% - 40px);
            + .btn {
                margin-left: 40px;
            }
        }
    }
    @media only screen and (min-width: $width-xl) {
        margin-top: 30px;

        .btn {
            width: 32%;
            + .btn {
                margin-left: 6.5%;
            }
        }
    }
}

.btn-primary {
    position: relative;
    z-index: 0;
    &:before {
        position: absolute;
        z-index: -1;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;

        display: block;

        content: '';
        transition: all 0.3s;

        opacity: 0;
        background: rgb(0,70,133);
        background: linear-gradient(90deg, rgba(0,70,133,1) 0%, rgba(0,174,239,1) 100%);
    }
    &:hover {
        &:before {
            opacity: 1;
        }
    }
}
input.btn-primary, .btn-primary[type="submit"] {
    transition: none;
    &:hover {
        border-color: transparent;
        background: rgb(0,70,133);
        background: linear-gradient(90deg, rgba(0,70,133,1) 0%, rgba(0,174,239,1) 100%);
    }
}

.btn-outline-primary {
    position: relative;
    z-index: 0;
    &:before {
        position: absolute;
        z-index: -1;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;

        display: block;

        content: '';
        transition: all 0.3s;

        opacity: 0;
        background: rgb(0,70,133);
        background: linear-gradient(90deg, rgba(0,70,133,1) 0%, rgba(0,114,205,1) 100%);
    }
    &:hover {
        &:before {
            opacity: 1;
        }
    }
}

.btn-outline-secondary {
}

.btn-link {
    &:after {
        display: inline-block;

        width: 9px;
        height: 9px;
        margin-bottom: 1px;
        margin-left: 10px;

        content: '';
        transform: rotate(45deg);

        border-top: solid 3px currentcolor;
        border-right: solid 3px currentcolor;
    }
    &:hover, &:focus {
        text-decoration: underline !important;

        color: $primary;
    }
}

.btn-link-white {
    color: #fff;
    &:after {
        display: inline-block;

        width: 9px;
        height: 9px;
        margin-bottom: 1px;
        margin-left: 10px;

        content: '';
        transform: rotate(45deg);

        border-top: solid 3px currentcolor;
        border-right: solid 3px currentcolor;
    }
    &:hover, &:focus {
        text-decoration: underline !important;

        color: #fff;
    }
    &:focus {
        outline-color: $blue-light;
        &:active {
            outline-color: $blue-light;
        }
    }
}

.btn-link--basic {
    text-transform: none;
    &:after {
        display: none;
    }
}

.btn-white {
    position: relative;
    z-index: 0;

    transition: all 0.3s;

    color: $primary;
    border-color: #fff;
    background-color: #fff;
    &:before {
        position: absolute;
        z-index: -1;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;

        display: block;

        content: '';
        transition: all 0.3s;

        opacity: 0;
        background: rgb(0,114,205);
        background: linear-gradient(90deg, rgba(0,174,239,1) 20%, rgba(0,114,205,1) 100%);
    }
    &:hover {
        color: #fff;
        &:before {
            transition: all 0.3s;
            opacity: 1;
        }
    }
    &:focus {
        outline-color: $blue-light;
        &:active {
            outline-color: $blue-light;
        }
    }
}
input.btn-white, .btn-white[type="submit"] {
    transition: none;
    &:hover {
        border-color: transparent;
        background: rgb(0,114,205);
        background: linear-gradient(90deg, rgba(0,174,239,1) 20%, rgba(0,114,205,1) 100%);
    }
}

.btn-outline-white {
    color: #fff;
    border-color: #fff;
    background-color: transparent;
    &:hover {
        color: $primary;
        background-color: #fff;
    }
    &:focus {
        outline-color: $blue-light;
        &:active {
            outline-color: $blue-light;
        }
    }
}

.message-center {
    position: relative;
    top: 9px;

    margin: 0px -10px 0 -10px;

    color: #fff;
    border-radius: 6px;
    background-color: #002B52;
    background-color: rgba(0,42,82,0.7);
    background-blend-mode: multiply;

    font-weight: 700;

    .close {
        position: absolute;
        top: 0px;
        right: 0px;

        color: #fff;
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a:not(.btn),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-top: 0;

        color: #fff;
    }
    a:not(.btn) {
        text-decoration: underline;

        font-weight: 700;
    }
    .message-center__row {
        padding: 10px 40px 10px 10px;
    }
    .message-center__body {
        position: relative;

        margin-bottom: 5px;
        p {
            font-size: 14px;
        }
        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .message-center__cta {
        margin-bottom: 5px;
        p {
            margin-bottom: 0;

            font-size: 14px;
        }
    }
    @media only screen and (min-width: $width-md) {
        margin-right: 0px;
        margin-left: 0px;

        .close {
            top: 12px;
            right: 18px;
        }
        .message-center__row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            padding: 20px 70px 20px 30px;

            > * {
                flex: 0 1 auto;
            }
        }
        .message-center__body {
            margin-bottom: 0px;
            padding-right: 30px;

            p {
                font-size: 16px;
            }
        }
        .message-center__cta {
            flex-shrink: 0;

            margin-bottom: 0px;
            p {
                font-size: 16px;
            }
        }
    }
}

// Form modifiers -------------------------------------------------------

.label,
label, legend {
    margin-bottom: 0.25rem;

    color: $grey-700;

    font-family: $primaryFont;
    font-size: 14px;
    font-weight: 400;
}

fieldset {
    margin-bottom: 1.5rem;
    .label,
    label {
        font-family: $primaryFont;
        font-weight: 400;
    }
}

.form-control, .custom-select {
    padding: 8px 15px;

    color: $grey-700;
    border-color: #dedcdc;

    font-family: $primaryFont;
    line-height: 1;
    &:focus {
        border-color: #0072CD;
        outline: 1px auto #0072CD;
        outline-style: solid;
        outline-offset: -1px;
        box-shadow: 0 2px 3px 0 #0072CD;
    }
}

input::placeholder {
    opacity: 1 !important;
    color: #adadaf !important;
}

.form-text {
    color: $grey-600;

    font-size: 10px;
}

.form-group, .input-group {
    margin-bottom: 1.5rem;
}

input[type="submit"],
input[type="button"],
{
    margin-bottom: 0;

    &:focus {
        // outline: 1px auto currentcolor;
        // outline-style: solid;
        // outline-offset: -1px;
        // box-shadow: 0 0 0 0.2rem rgba(0,174,239,1.0);
    }
}

.input-group {
    .btn-appended {
        width: 50px;
        padding: 15px 0px;

        color: $grey-900;
        border-width: 1px;
        border-color: #a3a3a3;
        border-left: none;
        background-color: $white;
        &:hover {
            color: $primary;
        }
    }
}

.btn-reset {
    padding: 0 0 0 25px;

    letter-spacing: normal;
    text-transform: none;

    color: $grey-900;
    background-image: url($imageDir+'icon-reset.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 18px;
}

.alph-filter {
    position: relative;
    .btn-reset {
        position: absolute;
        top: 0px;
        right: 0px;

        margin-bottom: 0.5rem;
    }
    @media only screen and (min-width: 1200px) {
        .btn-reset {
            top: auto;
            right: 0px;
            bottom: 0px;
        }
    }
}

// character count
.char-count {
    margin-top: 0.25rem;

    text-align: right;

    color: $grey-700;

    font-size: 10px;
}

// Episerver forms syling ---------------------------------------------------
.EPiServerForms {
    margin-bottom: 1.25rem;
    .Form__Description {
        margin-bottom: 1.5rem;
    }
    .Form__Status {
        .Form__Warning__Message {
            margin-bottom: 1.5rem;
            padding: 1rem;

            color: $grey-800;
            background-color: lighten($gold, 15%);
        }
    }
    //form grid
    .row {
        @extend .form-row;
    }
    //form group wrapper
    .Form__Element {
        @extend .form-group;
        select {
            @extend .custom-select;
        }
    }
    button.Form__Element, .btn {
        margin-bottom: 0;
        width: 100%;
        display: block;

        + * {
            margin-top: 10px;
        }
        @media only screen and (min-width: $width-sm) {
            width: auto;
            display: inline-block;

            + * {
                margin-top: inherit;
            }
        }
    }
    //labels and legends
    .Form__Element__Caption {
        @extend .label;
    }
    .FormTextbox__Input {
        @extend .form-control;
    }
    .FormSubmitButton {
        @extend .btn;
        @extend .btn-secondary;
    }
    .FormResetButton {
        @extend .btn;
        @extend .btn-outline-secondary;
    }
    .FormRange {
        .Form__Element__Caption {
            display: block;
        }
    }
    .Form__Element .Form__Element__ValidationError {
        padding: 4px 15px;

        color: #fff;
        background-color: $red;

        font-size: 0.8125rem;
    }
    //radio or checkbox wrapper
    .FormChoice {
        label {
            display: block;

            margin-left: 0px;
            padding: 3px 0;

            @extend .form-check-label;

            &:focus-within {
                // outline: 1px auto #0072CD;
                // outline-style: solid;
                // outline-offset: -1px;
            }
        }
        legend {
            font-family: $primaryFont;
            font-size: 14px;
            font-weight: 400;
        }
        .FormChoice__Input {
            position: relative;

            margin-right: 5px;

            &:focus {
                box-shadow: none;
            }
        }
        .FormChoice__Input--Checkbox {
        }
        .FormChoice__Input--Radio {
        }
    }
    .hide {
        display: none !important;
    }
}

// Modifier for forms on a dark background -----------------------------------
.form-on-dark {
    .label,
    label, legend {
        color: #fff;
    }
    .form-text {
        color: #fff;
    }
    .char-count {
        color: #fff;
    }
    .form-control, .custom-select {
        &:focus {
            border-color: $blue-light;
            outline-color: $blue-light;
            box-shadow: 0 2px 3px 0 $blue-light;
        }
    }

    // Episerver forms styling
    .EPiServerForms {
        .Form__Description {
            color: #fff;
        }
        .FormRange {
            color: #fff;
        }
        .FormSubmitButton {
            @extend .btn-outline-white;
        }
        .FormResetButton {
            @extend .btn-outline-white;
        }
    }
}

.langLink {
    .lang1 {
        font-weight: 700;
    }
    .lang2 {
        font-weight: 400;
    }
    &[data-lang ="es"] {
        .lang1 {
            font-weight: 700;
        }
        .lang2 {
            font-weight: 400;
        }
    }
    &[data-lang ="en"] {
        .lang1 {
            font-weight: 400;
        }
        .lang2 {
            font-weight: 700;
        }
    }
    &:hover {
        text-decoration: none;
        .lang2 {
            text-decoration: none;
        }
        .lang1 {
            text-decoration: underline;
        }
        &[data-lang ="es"] {
            .lang2 {
                text-decoration: underline;
            }
            .lang1 {
                text-decoration: none;
            }
        }
    }
}

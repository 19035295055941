//IE 11 fixes
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .profile-card__image, .hero--profile .hero--profile__image {
        height: auto;
    }

    @media only screen and (min-width: $width-lg) {
        .hero--home {
            .hero__content__row {
                height: 700px;
            }
        }
        .profile-card__image {
            height: auto;
        }
        .carousel-block--newsevent .owl-item .newsevent-slide__image {
            align-self: center;

            outline: none;
        }
    }
    @media only screen and (min-width: $width-xl) {
        .hero--profile .hero--profile__image {
            height: auto;
        }
    }
}

.campaign__nav {
    display: flex;
    float: right;
    overflow: hidden;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

    width: calc(100% - 140px);
    margin-right: -10px;
    padding-top: 20px;
    padding-left: 0;

    list-style: none;

    color: #fff;

    font-size: 14px;

    > * {
        flex: 0 0 auto;
    }

    li {
        display: inline-block;

        margin-left: 40px;
        &:first-child {
            margin-right: 0;
        }
    }

    a {
        color: #fff;
    }
    .btn {
        padding-right: 7px;
        padding-left: 7px;
    }
    @media only screen and (min-width: 375px) {
        width: calc(100% - 160px);
        margin-right: 0px;
        .btn {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        width: calc(100% - 300px);
        .btn {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
}

.campaign__body {
    margin-bottom: 30px;
    @media only screen and (min-width: $width-lg) {
        padding-right: 30px;
    }
}

.campaign__form-title {

}

.campaign__form-description {
    margin-bottom: 25px;
}

.campaign__footer {
    margin-top: 18px;
    .btn {
        display: block;

        width: 100%;
    }
    .btn + .btn {
        margin-top: 17px;
    }
    @media only screen and (min-width: $width-sm) {
        margin-top: 30px;
        .btn {
            display: inline-block;

            width: auto;
        }
        .btn + .btn {
            margin-top: 0px;
            margin-left: 15px;
        }
    }
}

.campaign--flipped {
    flex-direction: column-reverse;
    .well {
        margin-bottom: 40px;
    }
    @media only screen and (min-width: $width-lg) {
        flex-direction: row;
        .well {
            margin-bottom: 20px;
        }
    }
}

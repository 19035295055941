.hero--home {
    padding-top: 1px;
    padding-bottom: 0px;

    background: $blue url($imageDir+'waves-bkgnd02.jpg')no-repeat top center;
    background-size: cover;

    h1, .h1 {
        &:after {
            display: none;
        }
    }
    .hero__background {
        display: none;
    }
    .hero__content {
        max-width: 640px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 40px;
        padding-bottom: 70px;

        .btn {
            display: block;

            width: 100%;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 24px;
        }
    }
    .hero__image {
        margin-top: -45px;

        text-align: center;
    }
    @media only screen and (min-width: 450px) {
        .hero__image {
            img {
                max-width: 450px;
                height: auto;
            }
        }
    }
    @media only screen and (min-width: $width-sm) {
        .hero__content {
            .btn {
                display: inline-block;

                width: auto;
            }
        }
    }
    @media only screen and (min-width: $width-lg) {
        overflow: hidden;

        .hero__content__row {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            // height: 700px;
            min-height: 700px;
            > * {
                flex: 0 1 auto;
            }
        }

        .hero__content {
            width: 50%;
            margin-right: 0px;
            margin-bottom: 45px;
            margin-left: 0px;
            padding: 40px 40px 28px;
        }
        .hero__image {
            align-self: flex-end;

            width: 50%;
            margin-top: 0;

            text-align: center;
        }
    }
    @media only screen and (min-width: 1400px) {
        .hero__content {
            padding: 40px 80px 28px;
        }
    }
}

.hero--home--image {
    padding-top: 0px;
    padding-bottom: 0px;

    background-color: #fff;
    background-image: none;

    h1, .h1 {
        &:after {
            display: none;
        }
    }
    .hero__background {
        display: block;

        width: 100%;
        height: 44vh;
        max-height: 360px;

        background: $blue url($imageDir+'hero-texture.jpg')no-repeat center;
        background-position-x: 82%;
        background-size: cover;
    }
    .hero__content {
        margin-top: -55px;
        margin-right: -5px;
        margin-left: -5px;
        padding: 40px 15px 85px;

        color: $grey-800;
        background-color: rgba(255,255,255,0.95);

        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $grey-800;
        }
        a:not(.btn) {
            color: $primary;
        }
        .btn {
            display: block;

            margin-bottom: 0.75rem;
        }
    }
    .eyebrow {
        &:before {
            background-color: $blue-light;
        }
    }
    .hero__image {
        display: none !important;
    }
    .hero__footer {
        margin-top: 1.15rem;
        .btn {
            width: 100%;
            display: block;
        }
        .btn + .btn {
            margin-top: 8px;
        }
    }
    @media only screen and (min-width: $width-sm) {
        .hero__content {
            .btn {
                display: inline-block;
            }
        }
        .hero__footer {
            .btn {
                width: auto;
                display: inline-block;
            }
            .btn + .btn {
                margin-top: 0px;
                margin-left: 15px;
            }
            .btn + .btn-link, .btn + .btn-link-white, .btn-link + .btn, .btn-link-white + .btn {
                margin-left: 0px;
            }
        }
    }
    @media only screen and (min-width: $width-md) {
        .hero__content {
            padding: 40px 40px 85px;
        }
        .hero__footer {
            margin-top: 2rem;
        }
    }
    @media only screen and (max-width: $width-lg) and (orientation: landscape) {
        .hero__background {
            min-height: 220px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        overflow: hidden;

        .hero__background {
            height: auto;
            min-height: 700px;
            padding-top: 700px;

            background-position-x: 62%;
        }
        .hero__content {
            position: absolute;
            bottom: 0px;

            width: calc(60% - 115px);
            min-height: calc(700px - 95px);
            margin-top: 0px;
            margin-right: 0px;
            margin-left: 40px;
            padding: 40px 10% 73px 40px;
        }
    }
    @media only screen and (min-width: $width-xl) {
        .hero__background {
            background-position-x: 75%;
        }
        .hero__content {
            width: calc(50% - 115px);
            margin-left: 60px;
        }
    }
    @media only screen and (min-width: 2000px) {
        .hero__background {
            padding-top: 34.5%;

            background-position: center;
        }
        .hero__content {
            height: calc(34.5vw - 95px);
        }
    }
    @media only screen and (min-width: 2500px) {
        .hero__background {
            padding-top: 862px;
        }
        .hero__content {
            height: calc(862px - 95px);
        }
    }
}

//
// Menu expander related
//

.mln__expand-btn {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: $width-toggle-button;
    height: $height-toggle-button;
    border: 0;
    background-color: $color-menu-primary;
    color: white;
    text-indent: -9999px;
    font-size: 0;
    cursor: pointer;
}

.mln__expand-btn__burger {
    margin-right: auto;
    margin-left: auto;
    width: 1.438rem;
    height: .938rem;

    &:after,
    &:before {
        position: absolute;
        content: '';
    }

    &:before {
        top: -.375rem;
    }

    &:after {
        bottom: -.188rem;
    }
}

.mln__expand-btn__burger,
.mln__expand-btn__burger:before,
.mln__expand-btn__burger:after {
    position: relative;
    display: block;
    width: 1.2rem;
    height: .188rem;
    border-radius: 0;
    background-color: $color-burger-bar;

}




//
// Child menu toggle button
//

.mln__toggle-btn,
.mln__toggle-indicator {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    overflow: hidden;
    width: $width-toggle-button;
    height: $height-toggle-button;
    border: 0;
    border-radius: 0;
    background-color: darken($color-menu-primary, 5%);
    color: $color-toggle;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;

    -webkit-appearance: none;

    &:hover, &:focus {
        background-color: darken($color-menu-primary, 10%);
    }
}

.mln__toggle-indicator {
    z-index: 0;
    background-color: transparent;
    pointer-events: none;
}




//
// Button symbol related
//

.mln__toggle-btn__chevron {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: -.156rem;
    margin-left: -.344rem;
    width: $width-toggle-symbol-chevron;
    width: .75rem;
    height: $height-toggle-symbol-chevron;
    transition: transform 100ms ease-in-out;

    &:before, &:after {
        position: absolute;
        top: .125rem;
        display: block;
        width: .5rem;
        height: 0;
        border-top: .188rem solid $color-menu-link;
        border-radius: 1px;
        content: '';
        transition: transform 150ms ease-in-out;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

    }

    &:before {
        left: 0;
        -webkit-transform: rotate(45deg) translateZ(0);
        -ms-transform: rotate(45deg) translateZ(0);
        transform: rotate(45deg) translateZ(0);
    }

    &:after {
        right: 0;
        -webkit-transform: rotate(-45deg) translateZ(0);
        -ms-transform: rotate(-45deg) translateZ(0);
        transform: rotate(-45deg) translateZ(0);
    }
}

.mln__has-child--showing {
    > .mln__child-controls {
        .mln__toggle-btn__chevron {
            &:before {
                left: 0;
                -webkit-transform: rotate(-45deg) translateZ(0);
                -ms-transform: rotate(-45deg) translateZ(0);
                transform: rotate(-45deg) translateZ(0);
            }

            &:after {
                right: 0;
                -webkit-transform: rotate(45deg) translateZ(0);
                -ms-transform: rotate(45deg) translateZ(0);
                transform: rotate(45deg) translateZ(0);
            }
        }

        .mln__toggle-btn__symbol-custom {
            display: inline-block;
            -webkit-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            transform: rotate(-180deg);
        }
    }
}




//
// Navbar styling (above breakpoint)
//

@media (min-width: $mln-breakpoint) {
    .mln--navbar {
        .mln__expand-btn {
            display: none;
        }

        .mln__toggle-btn,
        .mln__toggle-indicator {
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }
        }

        .mln__toggle-btn__symbol-custom {
            display: inline-block;
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        .mln__child__collapse {
            .mln__toggle-btn__chevron {
                -webkit-transform: rotate(270deg);
                -ms-transform: rotate(270deg);
                transform: rotate(270deg);
            }

            .mln__toggle-btn__symbol-custom {
                display: inline-block;
                -webkit-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                transform: rotate(-90deg);
            }
        }

        .mln__level-1.mln__has-child--showing {
            > .mln__child-controls {
                .mln__toggle-btn__symbol-custom {
                    -webkit-transform: rotate(0deg);
                    -ms-transform: rotate(0deg);
                    transform: rotate(0deg);
                }

                .mln__toggle-btn__chevron {
                    -webkit-transform: translate3d(0, .188rem, 0);
                    -ms-transform: translate3d(0, .188rem, 0);
                    transform: translate3d(0, .188rem, 0);

                    &:before {
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }

                    &:after {
                        -webkit-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

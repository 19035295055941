.promo-block {
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0.75rem;
    }

    &.promo-block--on-dark {
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        a:not(.btn),
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        .eyebrow {
            color: #fff;
            &:before {
                background-color: $gold;
            }
        }
    }
    @media only screen and (min-width: $width-lg) {
        margin-bottom: 50px;
    }
}

.promo-block__body {

}

.promo-block__title {
    @extend .h5;
}

.promo-block__footer {
    margin-top: 1.25rem;
    .btn {
        width: 100%;
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 0px;
        }
    }
    @media only screen and (min-width: $width-sm) {
        .btn {
            width: auto;
            margin-bottom: 0;
        }
    }
}

// modules
// page layout chunks -------------------------------------------------------

.container-fluid {
    position: relative;

    max-width: 1700px;
    padding-right: 20px;
    padding-left: 20px;
    @media only screen and (min-width: $width-md) {}
    @media only screen and (min-width: $width-lg) {
        padding-right: 60px;
        padding-left: 60px;
    }
    @media only screen and (min-width: $width-xl) {
    }
    @media only screen and (min-width: 1400px) {
        padding-right: 100px;
        padding-left: 100px;
    }
}
// header bits (not main nav) ------------------------------------------------
.skip-link {
    position: fixed;
    z-index: -1001;
    top: -160px;
    left: 0;

    display: block;
    overflow: hidden;

    height: 0;
    padding: 15px 30px;

    text-decoration: none;
    text-transform: uppercase;

    opacity: 0;
    color: white;
    background: $primary;
    background-color: #222528;

    font-family: 'Geometria', sans-serif;
    font-weight: 800;

    &:focus {
        z-index: 9999;
        top: 0px;

        height: auto;
        padding: 15px 30px;

        text-decoration: none;

        opacity: 1;
        color: white;
        border-bottom: 2px solid $primary;
        outline: none;
    }
}
// offset anchor link placement
#main:target {
    margin-top: -80px;

    border-top: 80px solid transparent;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;

    @media only screen and (min-width: $width-xl) {
        margin-top: -165px;

        border-top: 165px solid transparent;
    }
}
*:target:before {
    display:block;

    height:80px; /* fixed header height*/
    margin:-80px 0 0; /* negative fixed header height */

    content:"";

    @media only screen and (min-width: $width-xl) {
        height:165px; /* fixed header height*/
        margin:-165px 0 0; /* negative fixed header height */
    }
}

// well - For when you just want a box around something ----------------------
.well {
    position: relative;

    margin-bottom: 0.75rem;
    padding: 30px 20px calc(30px - 0.75rem);

    background-color: $grey-100;

    &:after {
        display: table;
        clear: both;

        content: "";
    }
    @media only screen and (min-width: $width-lg) {
        padding: 60px 30px calc(60px - 0.75rem);
    }
}

.well--tight {
    padding: 15px 15px 5px;

    .form-group, .input-group {
        margin-bottom: 10px;
    }
    hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    @media only screen and (min-width: $width-lg) {
        padding: 15px 15px 5px;
    }

}

.well--border-top {
    border-top: solid 2px $primary;
}

.well--primary {
    background-color: $primary;

    @extend .white-text;
}

.well--secondary {
    background-color: $blue;

    @extend .white-text;
}

.well--fade {
    background: rgb(0,70,133);
    background: linear-gradient(180deg, rgba(0,70,133,1) 0%, rgba(0,124,187,1) 100%);

    @extend .white-text;
}

.arrow-link {
    position: relative;

    display: inline-block;

    margin-right: 30px;
    padding-right: 22px;

    text-align: left;
    white-space: normal;
    text-decoration: none !important;
    letter-spacing: 0.055rem;
    text-transform: uppercase;

    color: $primary;

    font-family: $primaryFont;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;

    + br + .arrow-link {
        margin-top: 0.5rem;
    }
    &:last-child {
        margin-right: 0;
    }
    &:after {
        position: absolute;
        top: calc(50% - 4px);
        right: 3px;

        display: block;

        width: 8px;
        height: 8px;

        content: '';
        transform: rotate(45deg);

        border-top: solid 3px currentcolor;
        border-right: solid 3px currentcolor;
    }

    &:hover, &:focus, &:active {
        text-decoration: underline !important;

        color: $primary;
    }
}

.arrow-link-back {
    position: relative;

    display: inline-block;

    margin-right: 30px;
    padding-left: 22px;

    text-align: left;
    white-space: normal;
    text-decoration: none !important;
    letter-spacing: 0.055rem;
    text-transform: uppercase;

    color: $primary;

    font-family: $primaryFont;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;

    + br + .arrow-link-back {
        margin-top: 0.5rem;
    }
    &:last-child {
        margin-right: 0;
    }
    &:after {
        position: absolute;
        top: calc(50% - 4px);
        left: 3px;

        display: block;

        width: 8px;
        height: 8px;

        content: '';
        transform: rotate(45deg);

        border-bottom: solid 3px currentcolor;
        border-left: solid 3px currentcolor;
    }

    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
}

.col-list-2 {
    @media only screen and (min-width: $width-sm) {
        columns: 2;
        column-gap: 30px;
    }
}

// used in style guide
.color-block {
    width: 100%;
    margin-bottom: 0.5rem;
    padding-top: 56.25%;

    background-color: $grey-200;
}

.article-meta {
    color: $grey-700;

    @extend .wysiwyg;
    a {
        text-decoration: underline;

        color: $grey-700;
    }
}

.share-bar {
    margin-bottom: 20px;

    .social-link {
        display: inline-block;

        margin-right: 12px;

        color: $blue;

        font-size: 1.75rem;
        line-height: 1.3;
        &:last-child {
            margin-right: 0px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        .social-link {
            display: block;
        }
    }
}

.close {
    position: relative;

    width: 40px;
    height: 40px;
    padding: 0;

    opacity: 1;
    color: $primary;
    &:before, &:after {
        position: absolute;
        top: calc(50% - 2px);
        left: calc(50% - 13px);

        display: block;

        width: 26px;
        height: 3px;

        content: '';
        transform: rotate(45deg);

        background-color: currentcolor;
    }
    &:after {
        transform: rotate(-45deg);
    }
}

.promo-image-bottom-right {
    position: relative;

    width: 100%;
    margin-top: 20px;

    text-align: center;
    img {
        display: inline-block;

        max-width: 100%;
        height: auto;
    }
    @media only screen and (min-width: $width-lg) {
        margin-top: 0px;

        text-align: right;
    }
}

.list-item-wrap {
    padding-left: 0;

    list-style: none;
}

.list-item {
    display: block;
    overflow: hidden;

    width: calc(100% + 20px);
    margin-right: -10px;
    margin-bottom: 20px;
    margin-left: -10px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;

    color: $grey-800;
    border-bottom: solid 1px $grey-300;

    &:hover {
        text-decoration: none;
        .list-item__title {
            text-decoration: underline;
        }
    }
    @media only screen and (min-width: $width-sm) {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        > * {
            flex: 0 1 auto;
        }
    }
    @media only screen and (min-width: $width-lg) {
        width: 100%;
        margin-right: 0;
        margin-right: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

.list-item__image {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    @media only screen and (min-width: $width-sm) {
        flex-shrink: 0;

        width: 160px;
        margin-right: 15px;
        margin-bottom: 0;
    }
}

.list-item-content {

}

.list-item__title {
    font-size: 18px;
    font-weight: 500;
    @media only screen and (min-width: $width-lg) {
        font-size: 20px;
    }
}

.list-item__date {

}

.list-item__path {
    word-wrap: break-word;
    word-break: break-all;

    color: $primary;

    font-size: 14px;
    line-height: 1.2;
}

.site-alert {
    position: relative;

    margin-bottom: 0;

    color: #000;
    background-color: #f3e2bc;

    .close {
        position: absolute;
        top: 10px;
        right: 10px;

        color: #000;
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a:not(.btn),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-top: 0;
        color: #000;
    }
    a:not(.btn) {
        text-decoration: underline;

        font-weight: 700;
    }
    .site-alert__row {
        padding: 50px 5px 20px;
    }
    .site-alert__body {
        position: relative;

        margin-bottom: 20px;
        // padding-left: 55px;
        p {
            font-size: 14px;
        }
        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .site-alert__icon {
        width: 40px;
        float: left;
        margin-right: 15px;
        margin-bottom: 15px;
        img {
            width: 100%;
            height: auto;
        }
    }
    @media only screen and (min-width: $width-md) {
        .close {
            top: 33px;
            right: 8px;
        }
        .site-alert__row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            padding: 42px 70px 42px 0px;

            > * {
                flex: 0 1 auto;
            }
        }
        .site-alert__body {
            margin-bottom: 0px;
            padding-left: 60px;

            p {
                font-size: 16px;
            }
        }
        .site-alert__icon {
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        .close {
            right: 64px;
        }
    }
    @media only screen and (min-width: 1400px) {
        .close {
            right: 104px;
        }
    }
}

.site-alert--high {
    color: #fff;
    background-color: #CC3300;

    .close {
        color: #fff;
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a:not(.btn),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #fff;
    }
}

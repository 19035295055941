.video-block {
    max-width: 610px;
    margin: 3rem auto;
}
.video-block__caption {
    margin-top: 10px;

    text-align: right;

    font-size: 10px;
    p {
        font-size: 10px;
    }
    > *:last-child {
        margin-bottom: 0px;
    }
    @media only screen and (min-width: $width-md) {
        margin-top: 20px;

        font-size: 12px;
        p {
            font-size: 12px;
        }
    }
}

.youtube {
	position: relative;

	overflow: hidden;

	padding-top: 56.25%;

	cursor: pointer;

	background-color: #000;
}
.youtube img {
	top: -16.82%;
	left: 0;

	width: 100%;

	opacity: 0.7;
}
.youtube .play-button {
	z-index: 1;

    overflow: hidden;

	width: 49px;
	height: 49px;

    transition: box-shadow 0.2s;
    text-indent: 9000px;
    text-wrap: nowrap;

	opacity: 1;
	border-radius: 65px;
	background-color: #fff;
	box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    &:before {
        width: 11px;
        margin-left: 2px;

    	content: "";

    	border-width: 11px 0 11px 18.0px;
    	border-style: solid;
    	border-color: transparent transparent transparent #333;
    }
    &:hover, &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(0,174,239,.75);
    }

    @media only screen and (min-width: $width-md) {
        width: 65px;
    	height: 65px;
        &:before {
            width: 15px;
            margin-left: 3px;

        	border-width: 15px 0 15px 26.0px;
        }
    }
}
.youtube .play-button
.youtube img,
.youtube .play-button {
	cursor: pointer;
}
.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
	position: absolute;
}
.youtube .play-button,
.youtube .play-button:before {
	top: 50%;
	left: 50%;

	transform: translate3d( -50%, -50%, 0 );
}
.youtube iframe {
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

// image placement -------------------------------------------

figure {
    display: table;

    width: auto;
    max-width: 100%;
    height: auto;
    margin: 2px auto 1.5rem;

    table-layout: fixed;

    &:first-child {
        margin-top: 2px;
    }

    > img {
        width: 100%;
    }
}

figcaption {
    display: table-caption;

    padding-top: 0.75rem;

    caption-side: bottom;

    color: $grey-700;

    font-size: 12px;
}

.image-right {
    float: none;

    max-width: calc(100% + 20px) !important;
    margin: 7px -10px 1.5rem;
    > img {
        width: 100%;
        height: auto;
    }
    @media only screen and (min-width: 500px) {
        float: right;

        max-width: calc( 50% - 25px ) !important;
		height: auto;
        margin: 2px 0 1.0rem 24px;
    }
	@media only screen and (min-width: 768px) {
		margin: 2px 0 1.0rem 40px;
    }
}

.image-center {
    width: auto;
    // max-width: 100% !important;
    // margin: 0px -10px 1.0rem;
    max-width: 100% !important;
    margin: 0px auto 1.0rem;
    > img {
        width: 100%;
        height: auto;
    }
    // @media only screen and (min-width: 500px) {
    //     max-width: 100% !important;
    //     margin: 0px auto 1.0rem;
    // }
}
img.image-center {
    display: block;
}

.image-left {
    float: none;

    max-width: calc(100% + 20px) !important;
    margin: 7px -10px 1.5rem;
    > img {
        width: 100%;
        height: auto;
    }
	@media only screen and (min-width: 500px) {
        float: left;

        max-width: calc( 50% - 25px ) !important;
        margin: 2px 24px 1.0rem 0;
    }
	@media only screen and (min-width: 1200px) {
        margin: 2px 40px 1.0rem 0;
    }
}

.img-scale {
    display: block;

    width: 100%;
	height: auto;
}

.pagination {
    margin-top: 30px;

    .page-link {
        width: 45px;
        height: 45px;
        padding-top: 13px;

        text-align: center;

        color: $grey-800;
        border: none;
        border-radius: 2px;
        background-color: transparent;
        &:hover {
            background-color: $grey-200;
        }
    }
    .page-item.active .page-link {
        color: $grey-800;
        border: none;
        background-color: $blue-light;
    }
    .page-item.disabled .page-link {
        opacity: 0.2;
        color: $grey-800;
        border: none;
        background-color: transparent;
    }

    @media only screen and (min-width: $width-lg) {
        margin-top: 40px;
    }
}

// styles for single click card functionality
.stat-card-block:hover {
    cursor: pointer;

    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
    .stat-card-block__percent  {
        border-bottom-color: $blue-light;
    }
}
.stat-card-block a {
    text-decoration: none;
}
.stat-card-block a:focus {
    text-decoration: underline;
}
.stat-card-block:focus-within {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
}
.stat-card-block:focus-within a:focus {
    text-decoration: none;
}

.stat-card-block {
    overflow: hidden;

    width: calc(100% + 10px);
    margin-right: -5px;
    margin-bottom: 10px;
    margin-left: -5px;
    padding: 10px;

    transition: box-shadow 0.3s;
    text-align: center;

    border: solid 1px $grey-300;
    background-color: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    @media only screen and (min-width: $width-sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        margin-left: 0;
    }
}

.stat-card-block__image {
    width: 100%;
    margin-bottom: 10px;
    img {
        width: 100%;
        height: auto;
    }
}

.stat-card-block__body {
    padding: 8px 0px;

    @extend .wysiwyg;
    > * {
        &:last-child {
            margin-bottom: 0.75rem;
        }
    }
    .btn {
        width: 100%;
        display: block;
    }
    .btn + .btn {
        margin-top: 8px;
    }
    @media only screen and (min-width: $width-sm) {
        padding: 10px 5px 0px;
    }
    @media only screen and (min-width: $width-lg) {
        .btn {
            width: auto;
            display: inline-block;
        }
    }
}

.stat-card-block__title {
    margin-bottom: 5px;

    font-size: 1rem;
    font-weight: 300;
    @media only screen and (min-width: $width-sm) {
        margin-bottom: 10px;

        font-size: 1.5rem;
    }
}

.stat-card-block__details {
    display: block;

    letter-spacing: 0.015rem;
    text-transform: uppercase;

    color: $grey-700;

    font-size: 12px;
    font-weight: 700;

    + .btn {
        margin-top: 25px;
    }

    @media only screen and (min-width: $width-sm) {
        font-size: 14px;
    }
}

.stat-card-block__percent {
    display: inline-block;

    margin-top: 10px;

    letter-spacing: -0.02rem;
    text-transform: none;

    color: $primary;
    border-bottom: solid 3px transparent;

    font-size: 3rem;
    font-size: 10.5vw;
    font-weight: 200;
    line-height: 1;

    -webkit-font-smoothing: antialiased;

    @media only screen and (min-width: $width-sm) {
        margin-top: 5px;

        font-size: 4.375rem;
        font-size: 8vw;
    }
    @media only screen and (min-width: $width-lg) {
        font-size: 5vw;
    }
    @media only screen and (min-width: 1400px) {
        font-size: 4.375rem;
    }
}

.stat-card-block__description {
    margin-top: 1rem;
    padding-top: 1.5rem;

    text-align: left;

    border-top: solid 1px $blue;
}

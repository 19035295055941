.account-nav {
    padding: 20px 0px;
    > * {
        width: 100%;
    }
    //overriding button hover state in header
    .btn-white {
        &:before {
            display: none;
        }
        &:hover {
            color: $primary;
        }
    }
    .dropdown-toggle {
        &:after {
            width: 10px;
            height: 10px;
            margin-left: 0.5rem;

            transform: rotate(45deg);

            border: solid 3px currentcolor;
            border-top: none;
            border-left: none;
        }
        &[aria-expanded='true'] {
            &:after {
                margin-bottom: -5px;

                transform: rotate(-135deg);
            }
        }
    }
    .dropdown-menu {
        width: 100%;
        margin-top: 0;

        border: none;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
    }
    .dropdown-item {
        white-space: normal;

        color: $primary;
        &:hover {
            text-decoration: underline;

            background-color: transparent;
        }
        &.active, &:active {
            color: #fff;
            background-color: $primary;
        }
    }
    .dropdown-item--with-icon {
        position: relative;

        padding-left: 3rem;

        color: $blue;

        font-weight: 700;
        i {
            position: absolute;
            top: 8px;
            left: 1.5rem;
        }
    }
    .dropdown.dropdown--arrow {
        .dropdown-toggle {
            padding-right: 6px;
            padding-left: 6px;
            &:after {
                display: none;
            }
            &[aria-expanded='true'] {
                color: $primary;
                background-color: #fff;
                &:before {
                    position: absolute;
                    z-index: 1001;
                    bottom: -35px;
                    left: calc(50% - 14px);

                    display: block;

                    width: 28px;
                    height: 28px;

                    content: '';
                    transform: rotate(45deg);

                    background-color: #fff;
                }
            }
        }
        .dropdown-menu {
            width: calc(100vw - 10px);
            margin-top: 20px;
            &.show {
                &:before {
                    position: absolute;
                    z-index: -2;
                    top: 0;
                    right: -5px;
                    left: -5px;

                    display: block;

                    height: calc(100vh - 75px);

                    content: '';

                    background-color: rgba(0,0,0,0.5);
                }
                &:after {
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    display: block;

                    content: '';

                    background-color: #fff;
                }
            }
        }
    }
    @media only screen and (min-width: 375px) {
        padding-right: 20px;
        padding-left: 20px;
    }
    @media only screen and (min-width: $width-sm) {
        .dropdown.dropdown--arrow {
            .dropdown-menu {
                width: 310px;
                &.show {
                    &:before, &:after {
                        display: none;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: $width-lg) {
        position: absolute;
        top: calc(50% - 25px);
        right: 0px;

        padding: 0;

        > * {
            display: inline-block;

            width: auto;
            margin-right: 12px;
            &:last-child {
                margin-right: 0px;
            }
        }
        .dropdown.dropdown--arrow {
            .dropdown-toggle {
                padding-right: 12px;
                padding-left: 12px;
            }
        }
    }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
MODIFIERS
–––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

// utility classes -----------------------------------------------------------
.white-text {
    color: $white;

    a:not(.btn) {
        color: $white;
        text-decoration: underline;

        &:active,
        &:focus,
        &:hover {
            color: $white;
        }
    }

    .btn {
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $white;
    }
}

.large-text {
    margin: 0;

    color: #8f9394;

    font-family: 'Geometria', sans-serif;
    font-size: 6.25rem;
    line-height: 1.25;
}

small {
    display: inline-block;

    font-family: 'Geometria', sans-serif;
    font-size: .875rem;
    line-height: 1.75;
}

.gray-background {
    background-color: #eceff1;
}

.clearfix {
    &:after {
        display: table;
        clear: both;

        content: "";
    }
}

.float-lg-left {
    margin: 0 0 1.75rem;

    @media (min-width: 992px) {
        margin: 0 1.75rem 0 0;
    }
}

.float-lg-right {
    margin: 0 0 1.75rem;

    @media (min-width: 992px) {
        margin: 0 0 0 1.75rem;
    }
}

.bottom-tight {
    margin-bottom: 0px !important;
}

.attribution {
    padding-left: 1.2rem;

    text-indent: -1.2rem;
}

.border-bottom {
    border-bottom-color: $grey-400 !important;
}

.with-divider {
    margin-bottom: 30px;
    &:after {
        display: block;

        width: 120px;
        height: 5px;
        margin-top: 28px;

        content: '';

        background-color: $gold;
    }
}

.with-divider-blue {
    margin-bottom: 30px;
    &:after {
        display: block;

        width: 120px;
        height: 5px;
        margin-top: 28px;

        content: '';

        background-color: $primary;
    }
}

.with-divider--centered {
    &:after {
        margin-left: auto;
        margin-right: auto;
    }
}

.desktop-only-inline-block {
    display: none !important;
    @media (min-width: 992px) {
        display: inline-block !important;
    }
}

.filter-bar {
    margin-bottom: 40px;
    padding: 20px 10px 0px;

    background-color: $blue-dark;

    .input-group {
        margin-bottom: 20px;
    }
    .input-group-text {
        padding-left: 0;

        text-transform: uppercase;

        border: none;
        background-color: transparent;

        font-size: 18px;
        font-weight: 300;
    }
    @media only screen and (min-width: $width-sm) {
        padding-right: 20px;
        padding-left: 20px;

        .input-group-text {
            padding-right: 18px;

            font-size: 20px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        padding-right: 0px;
        padding-left: 0px;
    }
}

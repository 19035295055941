/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
.print-logo {
    display: none;

    margin-top: 30px;
    margin-bottom: 30px;

    img {
        display: inline-block;

        width: auto;
        height: 40px;

        vertical-align: middle;
    }
}

.print-footer {
    display: none;

    width: 100%;
    padding-top: 30px;

    border-top: solid 1px $dark-tint;

    .print-footer-logo {
        float: left;

        margin-right: 15px;
        margin-bottom: 30px;
    }
}
@media print {
    * {
        color: #000 !important;
        background: transparent !important;
        /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    body {
        font-weight: normal;
    }

    .site-header {
        margin-top: 6rem;
    }

    a,
    a:visited {
        text-decoration: none !important;
    }

    a[href]:after {
        content: "";
        // content: " (" attr(href) ") ";
        // font-size: 80%;

        font-size: 10px;
    }

    .cell a[href]:after {
        content: "";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    /*
     * Don't show links for images, or javascript/internal links
     */
    .ir a:after,
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    blockquote,
    li,
    pre {
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
        /* h5bp.com/t */
    }

    img,
    tr {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }
    @page {
        margin: 1.5cm;
    }

    h1 {
        margin-bottom: 20px !important;
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .time,
    li,
    p {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 12px;
    }

    hr {
        border-color: #ccc !important;
    }

    .print-logo {
        display: block;
    }

    .print-footer {
        display: block;
    }

    .container-fluid {
        max-width: 100%;
        padding: 0;
    }

    .card {
        display: block;

        margin-right: 0;
        // border: solid 1px $dark-tint;
        // height: auto !important;
        margin-left: 0;
    }

    .hero--container {
        padding: 0;
    }

    .detail-page,
    .main__intro {
        margin: 0;
    }

    .main__intro {
        border-top: 1px solid #c9ced0; 
        padding-top: 20px;
    }

    .anchor-nav,
    .btn,
    .embed-responsive,
    .flex-header,
    .flex-row--alpha,
    .footer-bar,
    .footer-nav__row,
    .hide-for-print,
    .highlight-bar,
    .l-header,
    .l-off-canvas-overlay,
    .pagination,
    .sidebars,
    .skip-link,
    .site-footer,
    .sub-header,
    .subnav,
    .main__sidebar,
    .alpha-nav--container,
    .main__sub-nav,
    .hero__side,
    .owl-dots,
    .owl-carousel__play-pause,
    .callout__side,
    .callout__text a,
    .main__related-notes a,
    form {
        display: none !important;
    }

    .related-notes__notes .notes__note {
        border-top: 1px solid #c9ced0;
        padding: 20px 0 0;
    }

    .related-notes__notes .notes__note .note__title {
        margin: 0;
    }

    .card--accordion .card-header .btn {
        display: block !important;
    }

    .wrap--shiftup {
        margin-top: 0 !important;
    }

    .profile-tabs,
    .table-tabs,
    .view-tabs {
        .nav-link.active {
            border: solid 1px $dark-tint !important;
        }
    }

    .wrap--hero {
        height: auto;
        min-height: auto;
        &:after {
            background: none;
        }
    }

    .blockquote, blockquote {
        border: none;
    }

    .single-column, .section-content {
        max-width: none;
    }

    // print only column overrides
    .col-print-4 {
        max-width: 33.3333%;
    }
    .col-print-6 {
        max-width: 50%;
    }
    .col-print-12 {
        max-width: 100%;
    }

    .chart-surround--40-60 {
        display: table;

        width: 100%;

        .chart-surround__body {
            display: table-cell;

            width: 40%;
            height: 100%;

            vertical-align: middle;

            + .chart-surround__body {
                width: 60%;
            }
        }
    }
    .column-wrap {
        display: flex;
        align-items: stretch;
        align-items: flex-start;

        margin-right: -15px;
        margin-left: -15px;
    }
    .column-item {
        flex: 0 0 33%;

        padding-top: 0;
        padding-right: 15px;
        padding-left: 15px;

        border-top: none;
        border-right: solid 2px $white;

        &:last-child {
            border-right: none;
        }
    }

    // #Ducttape - weird fix for content clipping in Firefox
    body,
    html {
        height: auto;
        padding-top: 0;
    }

    .l-body {
        display: block;

        padding-top: 0;
        .row, .form-row {
            display: block;
            &:after {
        		display: table;
        		clear: both;

        		content: "";
        	}
            [class*="col-"] {
                float: left;
            }
        }
    }

    .l-surround {
        overflow: visible;
    }

    .l-body,
    .row {
        display: block;
    }

    .row {
        > [class^="col-"],
        > [class*=" col-"] {
            display: block;
            float: left;
        }
    }

    .card {
        display: block;
    }
}

.section-block {
    overflow: hidden;

    padding-top: 35px;
    padding-right: 0;
    padding-right: env(safe-area-inset-right);
    padding-bottom: calc(35px - 10px);
    padding-left: 0;
    padding-left: env(safe-area-inset-left);

    background-color: #fff;
    @media only screen and (min-width: $width-lg) {
        padding-top: 60px;
        padding-bottom: calc(60px - 20px);
    }
    @media only screen and (min-width: $width-xl) {
        padding-top: 80px;
        padding-bottom: calc(80px - 20px);
    }
}

.section-block__header {
    text-align: center;
    // spacing between section header and section content area
    > div > div > * {
        &:last-child {
            margin-bottom: 30px;
        }
    }

    &.section-block__header--left {
        text-align: left;
    }
}

.section-block__header-link {
    display: none;
    @media only screen and (min-width: $width-lg) {
        display: inline-block;
        float: right;

        margin-top: 20px;
        margin-right: 0;
        margin-left: 20px;
    }
}

.section-block__footer {
    margin-top: 20px;
    margin-bottom: 5px;

    text-align: center;
    .btn {
        display: block;
        clear: both;

        width: 100%;
    }
    .btn + .btn {
        margin-top: 15px;
    }
    @media only screen and (min-width: $width-sm) {
        .btn {
            display: inline-block;

            width: auto;
        }
        .btn + .btn {
            margin-top: 0px;
            margin-left: 10px;
        }
    }
    @media only screen and (min-width: $width-lg) {
        margin-top: 5px;

        text-align: right;
    }
}

.section-block__footer--left {
    margin-bottom: 15px;

    @media only screen and (min-width: $width-lg) {
        margin-top: 20px;
        margin-bottom: 0;

        text-align: left;
    }
}

.section-block__footer--centered {
    margin-bottom: 15px;

    @media only screen and (min-width: $width-lg) {
        margin-top: 20px;
        margin-bottom: 0;

        text-align: center;
    }
}

.section-block--waves {
    background: $blue url($imageDir+'waves-bkgnd01.jpg')no-repeat top center;
    background-size: cover;
    color: #fff;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a:not(.btn),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #fff;
    }
    .eyebrow {
        color: #fff;
        &:before {
            background-color: $gold;
        }
    }
    a {
        text-decoration: underline;
        &:focus {
            outline-color: $blue-light;
        }
    }
}

.section-block--blue-fade {
    background: rgb(0,70,133);
    background: linear-gradient(180deg, rgba(0,70,133,1) 0%, rgba(0,114,205,1) 100%);
    color: #fff;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a:not(.btn),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #fff;
    }
    .eyebrow {
        color: #fff;
        &:before {
            background-color: $gold;
        }
    }
    a {
        &:focus {
            outline-color: $blue-light;
        }
    }
}

.section-block--grey {
    background-color: $grey-100;
}

.section-block--tight-top {
    padding-top: 0;
}
